import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { _ } from "../config/languages/i18n";
import TagManager from 'react-gtm-module'
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Docs = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Docs Page'
            },
            event: 'docs_page'
        })
    }, [])

    return (
        <div >
            <Helmet>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            <Navbar />
            <div className="container py-2 mt-5">
                <div className="row ms-2" >

                    <div className="col-md-12 mb-2">
                        <h6 className="homeText">{_('logos')}</h6>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_logo.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_black_01} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_logo.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_black_01} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_logo_black.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_black_02} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_logo_black.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_black_02} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_200m.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded p-2" src={images.vidipass_200m} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_icon.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_icon.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_icon.ai" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_icon.pdf" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_icon.psd" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_72dpi_icon.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipassbank_logo_colorful.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded p-2" src={images.vidipassbank_colourful} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipassbank_logo.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipassbank_colourful_logo} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipassbank.pdf" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipassbank} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/VidipassPartner.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_partner} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/VidipassPartner.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_partner} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/VidipassPartner_white.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_partner} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/VidipassPartner_white.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_partner} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_yeni_nesil_biletleme_white.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_logo_new_generation_ticketing} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_yeni_nesil_biletleme.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_logo_new_generation_ticketing} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_yeni_nesil_biletleme.pdf" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_logo_new_generation_ticketing} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_bilet_icin.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_bilet_icin_white.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_bilet_icin_framed.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_bilet_icin_white_framed.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_bilet_icin_framed_black.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr_framed} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_TR_bilet_icin_white_framed_black.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr_framed} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_bilet_icin.pdf" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_tr} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_EN_For_Ticket.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_EN_For_Ticket_white.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_EN_For_Ticket_framed.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_EN_For_Ticket_white_framed.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_EN_For_Ticket_framed_black.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en_framed} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/Vidipass_EN_For_Ticket_white_framed_black.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en_framed} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_logo_framed.ai" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_logo_framed.pdf" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="p-2" src={images.vidipass_for_ticket_en} />
                            </div>
                        </a>
                    </div>

                    <div className="col-md-12 mb-2">
                        <h6 className="homeText">{_('icons')}</h6>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_qr.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_qr_200px} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_key.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_key} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/vidipass_person.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_person} />
                            </div>
                        </a>
                    </div>

                    <div className="col-md-12 mb-2">
                        <h6 className="homeText">{_('ad')}</h6>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Freedom_in_the_Ticket_1080x1920.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad1} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Freedom_in_the_Ticket_2835x5669.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad1} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Freedom_in_the_Ticket_1920x350.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad1} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Pass_Dogrusu_Bilette_Ozgurluk_900x1600.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad2} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Pass_Dogrusu_Bilette_Ozgurluk_1920x350.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad2} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Innovation_in_Ticket_1080x1920.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad3} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Innovation_in_Ticket_2835x5669.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad3} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Innovation_in_Ticket_1920x350.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad3} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Pass_Dogrusu_Bilette_Yenilik_1080x1920.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad4} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Pass_Dogrusu_Bilette_Yenilik_1920x350.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad4} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Peace_in_Ticket_1080x1920.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad5} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Peace_in_Ticket_2835x5669.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad5} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_Pass_is_More_Peace_in_Ticket_1920x350.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad5} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Pass_Dogrusu_Bilette_Huzur_900x1600.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad6} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Pass_Dogrusu_Bilette_Huzur_1920x350.png" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad6} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_For_Activities_1080x1920.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad7} />
                            </div>
                        </a>
                    </div>
                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_EN_For_Activities_2835x5669.jpg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad7} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_Aktiviteler_icin_900x1600.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad8} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/docs/ad/Vidipass_Ad_TR_yeni_nesil_biletleme_2835x5669.jpeg" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.vidipass_ad9} />
                            </div>
                        </a>
                    </div>

                    <div className="col-md-12 mb-2">
                        <h6 className="homeText">{_('videos')}</h6>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/Konser_Tiyatro_Seminer.mov" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>


                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/Vidipass_KTFGS_abi_vnc_17122024_1080x1080px.mp4" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/Vidipass_KTFGS_abi_vnc_17122024_1080x1920px.mp4" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/Vidipass_KTFGS_abi_vnc_17122024_1920x1080px.mp4" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img className="rounded" src={images.vidipass_vp_icon} />
                            </div>
                        </a>
                    </div>

                    <div className="col-md-12 mb-2">
                        <h6 className="homeText">{_('sounds')}</h6>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Notification_1s.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Notification_1s.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Jingle_08s.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Jingle_08s.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Ad_08s.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Ad_08s.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Jingle_14s.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Jingle_14s.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Ad_14s.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Ad_14s.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Jingle_59s.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Jingle_59s.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Aktivite_Biletleri_icin_Vidipass.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Aktivite_Biletleri_icin_Vidipass.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Aktivite_Biletleri_icin_Vidipass_Nokta_Com.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Aktivite_Biletleri_icin_Vidipass_Nokta_Com.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Bilet_icin_Vidipass.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Bilet_icin_Vidipass.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Bilet_icin_Vidipass_Nokta_Com.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Bilet_icin_Vidipass_Nokta_Com.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Ekonomik_Ve_Güvenli_Biletleme.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Ekonomik_Ve_Güvenli_Biletleme.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Reklam.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Vidipass_Reklam.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Yeni_Nesil_Biletleme.mp3" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                    <div className="col-xs-3 col-sm-4 col-md-3 col-lg-2 col-4">
                        <a href="https://kaminovasyon.com/public/images/vidipass/sounds/Yeni_Nesil_Biletleme.wav" download target="_blank">
                            <div className="card mb-4 shadow-sm tools-card w-75">
                                <img src={images.speaker} />
                            </div>
                        </a>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Docs