import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import globals from "../config/globals/globals";
import { Cookies } from "react-cookie";
import { _ } from "../config/languages/i18n";
import ShowMoreText from "react-show-more-text";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const Stages = () => {
  const [hallPlans, setHallPlans] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [token, setToken] = useState("");
  const cookies = new Cookies();
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");
  const [positionType, setPositionType] = useState(null);

  const [seatChart, setSeatChart] = useState([]);
  const [seatPlanModal, setSeatPlanModal] = useState(false);
  const [groupCenters, setGroupCenters] = useState({}); // Grupların merkezlerini saklar
  const [showIcon, setShowIcon] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // Zoom seviyesi state'i
  const [deleteStageModal, setDeleteStageModal] = useState(false);
  const [stageId, setStageId] = useState("")
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    if (showModal) {
      setShowIcon(true);
      const timer = setTimeout(() => setShowIcon(false), 5000);
      return () => clearTimeout(timer); // Cleanup
    }
  }, [showModal]);




  const HTTP = axios.create({ baseURL: globals.apiUrl });

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser?.result) {
      setToken(getuser.token);
    }
  }, []);

  const getGroupsByStatus = () => {
    const groups = {};

    seatChart.forEach((row, rowIndex) => {
      row.hall_seat.forEach((seat, colIndex) => {
        const groupName = seat.category_name || seat.status; // Eğer grubu varsa, grup ismi; yoksa status kullanacağız

        // Eğer grup daha önce tanımlanmadıysa, oluştur
        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        // Grup içindeki koltukları ekle
        groups[groupName].push({ rowIndex, colIndex });
      });
    });

    return groups;
  };

  const calculateGroupCenter = (group) => {
    if (!group || group.length === 0) return { center: null, positionType: 4 };

    const rows = group.map((seat) => seat.rowIndex);
    const cols = group.map((seat) => seat.colIndex);

    const minRow = Math.min(...rows);
    const maxRow = Math.max(...rows);
    const minCol = Math.min(...cols);
    const maxCol = Math.max(...cols);

    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;

    const closestSeat = group.reduce((closest, seat) => {
      const distance = Math.sqrt(
        Math.pow(seat.rowIndex - centerRow, 2) +
          Math.pow(seat.colIndex - centerCol, 2)
      );
      return closest === null || distance < closest.distance
        ? { seat, distance }
        : closest;
    }, null);

    const positionType =
      Number.isInteger(centerRow) && Number.isInteger(centerCol)
        ? 1
        : Number.isInteger(centerRow)
        ? 2
        : Number.isInteger(centerCol)
        ? 3
        : 4;

    return { center: closestSeat?.seat || null, positionType };
  };

  const calculateAllGroupCenters = () => {
    const groups = getGroupsByStatus(); // Tüm grupları al
    const newGroupCenters = {};

    Object.keys(groups).forEach((groupName) => {
      newGroupCenters[groupName] = calculateGroupCenter(groups[groupName]); // Her grup için merkezi hesapla
    });

    setGroupCenters(newGroupCenters); // Merkezi gruplarla birlikte güncelle
  };

  useEffect(() => {
    calculateAllGroupCenters();
  }, [seatChart]);

  useEffect(() => {
    setLoading(true)
    if (token) {
      HTTP.get("/getHallPlan", {
        headers: { Authorization: `bearer ${token}` },
      }).then((res) => {
        setHallPlans(res.data.hallPlan);
        setLoading(false)
      });
    }
  }, [token]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser?.result) {
      setUserId(getuser.user.id);
      setUserType(getuser.user.type);
    }
  }, []);

  const columns = [
    {
      name: _("plan"),
      cell: (row) => (
        <button
          className="bi bi-arrows-angle-expand"
          onClick={() => {
            setSelectedRow(row); // Seçilen satırı kaydet
            setSeatChart(row.hall_row);
            setShowModal(true);
            setZoomLevel(1)
          }}
        ></button>
      ),
      minWidth: "15px",
    },
    {
      name: _("stage_name"),
      selector: (row) => row.title,
      sortable: true,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: _("city"),
      selector: (row) => row.city?.name,
      sortable: true,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: _("address"),
      selector: (row) => row.hall_address,
      sortable: true,
      minWidth: "350px",
      wrap: true,
    },
    {
      name: _("capacity"),
      selector: (row) => row.capacity,
      sortable: true,
      minWidth: "110px",
      wrap: true,
    },
    {
      name: _("lat"),
      selector: (row) => row.hall_lat,
      sortable: true,
      minWidth: "120px",
      wrap: true,
    },
    {
      name: _("lon"),
      selector: (row) => row.hall_lon,
      sortable: true,
      minWidth: "120px",
      wrap: true,
    },
    {
      name: _("description"),
      selector: (row) => (
        <ShowMoreText
      lines={3}
      more={_("continue")}
      less={_("close")}
        >
        {row.hall_description}
        </ShowMoreText>
      ),
      sortable: true,
      minWidth: "150px",
      wrap: true,
    },
    userType == 3 &&
    {
      name: _("actions"),
      button: true,
      minWidth: "70px",
      cell: (row) => (
        <span className="d-flex">
          
            <button
              style={{ border: "none" }}
              className="mx-1 p-1 saloonButton rounded"
              onClick={() => window.location.href=globals.url + `i/editStages/${row.id}` }
              target="_blank"
              rel="noopener noreferrer"
              title={_("edit_stage")}
            >
              <span
                style={{ color: "#FFFFFF" }}
                class="bi bi-pencil m-1"
              ></span>
            </button>

            <button
              style={{ border: "none" }}
              className="mx-1 p-1 saloonButton rounded"
              onClick={(e)=>deleteStage(row.id)}
              target="_blank"
              rel="noopener noreferrer"
              title={_("delete_stage")}
            >
              <span
                style={{ color: "#FFFFFF" }}
                class="bi bi-trash m-1"
              ></span>
            </button>
        </span>
      ),
    },
  ];

  const deleteStage = (val) =>{
    setStageId(val)
    setDeleteStageModal(true)
  }

 

  const deleteHall = () => {
    setLoading(true);
    formData.append("id",stageId)
    

    HTTP.post("/deleteHallPlan", formData, {
      headers: { Authorization: `bearer ${token}` },
    }).then((res) => {
     
      if (res.data.result) {
        setLoading(false);
        setDeleteStageModal(false)
        setStageId("")
        toast(_("successful"), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          closeButton: false,
        });
      }
    });
  };

  const normalizeText = (text) => {
    return text
      ?.toUpperCase()
      .replace(/Ç/g, "C")
      .replace(/Ğ/g, "G")
      .replace(/I/g, "I")
      .replace(/İ/g, "I")
      .replace(/Ö/g, "O")
      .replace(/Ş/g, "S")
      .replace(/Ü/g, "U");
  };
  console.log(seatChart);

  const getStageSeatsMatrix = () => {
    const stageSeats = [];
    seatChart.forEach((row, rowIndex) => {
      row.hall_seat.forEach((seat, colIndex) => {
        if (seat.status === "stage") {
          stageSeats.push({ rowIndex, colIndex });
        }
      });
    });
    return stageSeats;
  };
  const findCenterStageSeat = () => {
    const stageSeats = getStageSeatsMatrix();
    if (stageSeats.length === 0) return null;

    // En küçük ve en büyük satır/sütun indekslerini bul
    const minRow = Math.min(...stageSeats.map((seat) => seat.rowIndex));
    const maxRow = Math.max(...stageSeats.map((seat) => seat.rowIndex));
    const minCol = Math.min(...stageSeats.map((seat) => seat.colIndex));
    const maxCol = Math.max(...stageSeats.map((seat) => seat.colIndex));

    // Satır ve sütunlar arasında ortalama merkezi bul
    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;
    // En yakın sahne koltuğunu bul

    const closestSeat = stageSeats.reduce((closest, seat) => {
      const distance = Math.sqrt(
        Math.pow(seat.rowIndex - centerRow, 2) +
          Math.pow(seat.colIndex - centerCol, 2)
      );
      if (closest === null || distance < closest.distance) {
        return { seat, distance };
      }
      return closest;
    }, null);

    return closestSeat ? closestSeat.seat : null;
  };

  const centerStageSeat = findCenterStageSeat();
  useEffect(() => {
    const stageSeats = getStageSeatsMatrix();
    const minRow = Math.min(...stageSeats.map((seat) => seat.rowIndex));
    const maxRow = Math.max(...stageSeats.map((seat) => seat.rowIndex));
    const minCol = Math.min(...stageSeats.map((seat) => seat.colIndex));
    const maxCol = Math.max(...stageSeats.map((seat) => seat.colIndex));

    // Satır ve sütunlar arasında ortalama merkezi bul
    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;
    if (centerRow !== null && centerCol !== null) {
      if (Number.isInteger(centerRow) && Number.isInteger(centerCol)) {
        setPositionType(1);
      } else if (Number.isInteger(centerRow) && !Number.isInteger(centerCol)) {
        setPositionType(2);
      } else if (!Number.isInteger(centerRow) && Number.isInteger(centerCol)) {
        setPositionType(3);
      } else {
        setPositionType(4);
      }
    }
  }, [seatChart]); // Boş bağımlılık dizisi ile yalnızca bileşen montajında çalışır
 

  const handleWheelZoom = (e) => {
    setZoomLevel((prev) => {
      if (e.deltaY < 0) return Math.min(prev + 0.1, 2); // Yakınlaştır
      if (e.deltaY > 0) return Math.max(prev - 0.1, 0.5); // Uzaklaştır
      return prev;
    });
  };

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.1, 2)); // + Butonu ile yakınlaştır
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.1, 0.5)); // - Butonu ile uzaklaştır
  };
  



  return (
    <div>
     

      
      <Navbar />
      {loading ? <Loader/>:
      <>
      <div className="container mb-5 mt-5">
        <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
          <div className="card card1 col">
            <div className="row justify-content-center">
              <div className="col-12 ">
                <div className="card-body row">
                  <h1 style={{ fontSize: 20 }} className="title">
                    {_("stages")}
                  </h1>
                  <hr />
                </div>
                <div className="col-sm-12 col-md-4 mb-2 mx-2">
                  <input
                    type="search"
                    className="form-control bg-white headerInput text-muted"
                    placeholder={_("search")}
                    aria-label={_("search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="card-body">
                <DataTable
                columns={columns}
                responsive
                pagination
                paginationPerPage={100}
                noDataComponent={_("no_records")}
                paginationRowsPerPageOptions={[100, 500, 1000]}
                data={hallPlans
                  .filter((item) => {
                    if (searchTerm === "") return item;
                    return (
                      normalizeText(item.title)?.includes(normalizeText(searchTerm)) ||
                      normalizeText(item.capacity?.toString()).includes(
                        normalizeText(searchTerm)
                      ) ||
                      normalizeText(item.hall_address)?.includes(normalizeText(searchTerm)) ||
                      normalizeText(item.hall_lat?.toString()).includes(
                        normalizeText(searchTerm)
                      ) ||
                      normalizeText(item.hall_lon?.toString()).includes(
                        normalizeText(searchTerm)
                      ) ||
                      normalizeText(item.hall_description)?.includes(
                        normalizeText(searchTerm)
                      ) ||
                      normalizeText(item.city?.name)?.includes(
                        normalizeText(searchTerm)
                    )
                  )
                  })
                  .sort((a, b) => a.title.localeCompare(b.title)) // Sadece sahne adı için sıralama
                }
              />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

<Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-5" style={{ color: "#933CAC" }}>
          {_("stage_details")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedRow ? (
          <div className="row gap-2" style={{ fontSize: 12 }}>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("stage_name")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.title}</p>
            </div>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("capacity")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.capacity}</p>
            </div>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("city")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.city?.name}</p>
            </div>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("address")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.hall_address}</p>
            </div>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("lat")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.hall_lat}</p>
            </div>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("lon")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.hall_lon}</p>
            </div>
            <div className="col-12 d-flex">
              <p className="fw-bold text-muted mb-0 col-md-6">
                {_("description")}
              </p>
              <p className="mb-0 col-md-6">{selectedRow.hall_description}</p>
            </div>
          </div>
        ) : (
          <p>{_("no_records")}</p>
        )}
        
        <div
          id="seat-map2"
          className="seat-map2 border border-secondary-subtle p-2 rounded-2 mt-1"
          onClick={() => setSeatPlanModal(true) + setShowModal(false)}
        >
          <div
            onWheel={handleWheelZoom}
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: "center",
              overflow: "auto",
            }}
          >
            {seatChart.map((row, rowIndex) => (
              <div key={rowIndex} className="seat-row">
                {row.hall_seat.map((seat, colIndex) => {
                  const isStage = seat.status === "stage";
                  const isSeat = seat.status === "seat";
                  const isStanding = seat.status === "standing";
                  const isClickable =
                    seat.status === "seat" || seat.status === "standing";

                  const groupCenter =
                    groupCenters[seat.category_name || seat.status];
                  const isGroupCenter =
                    groupCenter &&
                    groupCenter.center &&
                    groupCenter.center.rowIndex === rowIndex &&
                    groupCenter.center.colIndex === colIndex;
                  const positionType = groupCenter?.positionType || 4;

                  return (
                    <div key={colIndex} style={{ backgroundColor: seat.color }}>
                      {isClickable ? (
                        <div
                          className="chartSeatStanding"
                          style={{
                            backgroundColor: seat.color,
                            border: "none",
                            cursor: "default"
                          }}
                          onClick={() => ''}
                        >
                          {isGroupCenter && (isSeat || isStanding) && (
                            <div
                              className="rounded"
                              style={{
                                position: "absolute",
                                top:
                                  positionType === 1
                                    ? "50%"
                                    : positionType === 2
                                    ? "50%"
                                    : positionType === 3
                                    ? "100%"
                                    : "100%",
                                left:
                                  positionType === 1
                                    ? "50%"
                                    : positionType === 2
                                    ? "100%"
                                    : positionType === 3
                                    ? "50%"
                                    : "100%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 3,
                                color: "#FFFFFF",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {seat.category_name}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="chartSeatStanding"
                          style={{
                            cursor: "default",
                            backgroundColor: seat.color,
                          }}
                        >
                          {isStage && isGroupCenter && (
                            <div
                              className="rounded"
                              style={{
                                position: "absolute",
                                top:
                                  positionType === 1
                                    ? "50%"
                                    : positionType === 2
                                    ? "50%"
                                    : positionType === 3
                                    ? "100%"
                                    : "100%",
                                left:
                                  positionType === 1
                                    ? "50%"
                                    : positionType === 2
                                    ? "100%"
                                    : positionType === 3
                                    ? "50%"
                                    : "100%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 3,
                                color: "#FFFFFF",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>{_("stage")}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>

        {/* Zoom Buttons */}
        <div className="zoom-controls d-flex justify-content-center mt-3">
          <button
            className="btn mx-2"
            onClick={handleZoomIn}
            disabled={zoomLevel >= 2}
            style={{backgroundColor: "#933CAC", color: "#FFF"}}
          >
            +
          </button>
          <button
            className="btn mx-2"
            onClick={handleZoomOut}
            disabled={zoomLevel <= 0.5}
            style={{backgroundColor: "#933CAC", color: "#FFF"}}
          >
            -
          </button>
        </div>
      </Modal.Body>
    </Modal>
      



      <Modal
  show={seatPlanModal}
  onHide={() => setSeatPlanModal(false)}
  size="xl"
  centered
  className="custom-modal"
>
  <Modal.Header closeButton className="">
    <Modal.Title className="fs-5" style={{ color: "#933CAC" }}>
      {_("stage_details")}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div
      id="seat-map"
      className="seat-map border border-secondary-subtle rounded-2 mt-1"
    >
      {seatChart?.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className="seat-row d-flex justify-content-center"
        >
          {row.row_label ? (
            <div className="seat-row-label">
              <span
                style={{
                  border: "3px solid #A5A7A9",
                  boxShadow: "0 0 10px 0 rgba(100,100,100,1)",
                  display: "flex",
                  justifyContent: "center"
                }}
                className="seat-label rounded"
              >
                {row.row_label}
              </span>
            </div>
          ) : (
            <div className="seat-row-label" />
          )}
          {row.hall_seat.map((seat, colIndex) => {
            const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
            const isEmpty = seat.status === "empty";
            const isClose = seat.is_active === 0;

            const isClickable =
              seat.status === "seat" || seat.status === "standing";
            const isSeat = seat.status === "seat";
            const isStanding = seat.status === "standing";
            const isCenterStage =
              centerStageSeat &&
              centerStageSeat.rowIndex === rowIndex &&
              centerStageSeat.colIndex === colIndex;
            const groupCenter =
              groupCenters[seat.category_name || seat.status];
            const isGroupCenter =
              groupCenter &&
              groupCenter.center &&
              groupCenter.center.rowIndex === rowIndex &&
              groupCenter.center.colIndex === colIndex;
            const positionType = groupCenter?.positionType || 4;

            return (
              <div
                style={{
                  backgroundColor: isStage || isStanding ? seat.color : ""
                }}
              >
                {isClickable ? (
                  <div
                    className={`seat2 ${isStage ? "stage-seat" : ""}  ${
                      seat.position_type === "left" && "leftLoveSeat"
                    } ${seat.position_type === "right" && "rightLoveSeat"} ${
                      seat.type === "disable" && "disableSeat"
                    } ${seat.type === "normal" && "normalSeat"} ${
                      isStanding && isClose ? "" : ""
                    } ${isStanding && !isClose ? "" : ""}`}
                    style={{
                      backgroundColor: seat.color,
                      cursor: "default",
                      color: "#FFFFFF",
                      letterSpacing: "1px",
                      position: "relative", // Koltuk numarası ve grup merkezi yerleşimi için gerekli
                    }}
                    onClick={() => ""} // Dummy onClick, asıl işlevi Popover kullanır
                  >
                    {seat.label && !isStanding && (
                      <span
                        className="seat-label d-flex justify-content-center"
                        style={{
                          position: "relative",
                          zIndex: 2, // Grup merkezi göstergesinin altına düşmemesi için
                        }}
                      >
                        {seat.label}
                      </span>
                    )}

                   
                    {isStanding && isGroupCenter && (
                      <div
                        className="rounded"
                        style={{
                          position: "absolute",
                          top:
                            positionType === 1
                              ? "50%"
                              : positionType === 2
                              ? "50%"
                              : positionType === 3
                              ? "100%"
                              : "100%",
                          left:
                            positionType === 1
                              ? "50%"
                              : positionType === 2
                              ? "100%"
                              : positionType === 3
                              ? "50%"
                              : "100%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 3,
                          color: "#FFFFFF",
                        }}
                      >
                        <span style={{ fontSize: 12 }}>{seat.category_name}</span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`seat ${seat.status}`}
                    style={{
                      border: "none",
                      cursor: "default", // Tıklanamaz koltuklarda default işaretçi
                    }}
                  >
                    {isCenterStage &&
                      isStage &&
                      (positionType === 1 ? (
                        <div
                          className="rounded"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 3,
                            color: "#FFFFFF",
                          }}
                        >
                          <span className="m-5">{_("stage")}</span>
                        </div>
                      ) : positionType === 2 ? (
                        <div
                          className="rounded"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "100%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 3,
                            color: "#FFFFFF",
                          }}
                        >
                          <span className="m-5">{_("stage")}</span>
                        </div>
                      ) : positionType == 3 ? (
                        <div
                          className="rounded"
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 3,
                            color: "#FFFFFF",
                          }}
                        >
                          <span className="m-5">{_("stage")}</span>
                        </div>
                      ) : (
                        <div
                          className="rounded"
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "100%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 3,
                            color: "#FFFFFF",
                          }}
                        >
                          <span className="m-5">{_("stage")}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  </Modal.Body>
      </Modal>


      <Modal show={deleteStageModal} onHide={() => setDeleteStageModal(false)+setStageId("")}>
                <Modal.Header closeButton style={{ background: "#fff" }}>
                    <Modal.Title
                        style={{ fontSize: 16 }}
                    ><span className="homeText">{_("logout")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <h6 className="homeText">{_("sure")}</h6>
                    </div>
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col text-end" onClick={() => deleteHall()}>
                                <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                    <i className="bi bi-check-circle-fill"></i>
                                </a>
                            </div>
                            <div className="col text-end" onClick={() => setDeleteStageModal(false)+setStageId("")}>
                                <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                    <i className="bi bi-x-circle-fill"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>




            </>
          }
     

      <Footer />
    </div>
  );
};

export default Stages;