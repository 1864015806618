const images = {
    vidipass_logo: require ('./images/icons/vidipass_logo.png'),
    vidipass_vp_icon:require ('./images/icons/vidipass_vp_icon.png'),
    vidipass_trans:require ('./images/thumb/vidipass_trans.png'),
    profile_icon: require ('./images/icons/profile_icon.png'),
    login_button_icon: require ('./images/icons/login_button_icon.png'),
    register_button_icon: require ('./images/icons/register_button_icon.png'),
    explore_disable_icon:require ('./images/icons/explore_disable_icon.png'),
    actividi: require ('./images/icons/actividi.png'),
    apple: require ('./images/icons/apple.png'),
    google: require ('./images/icons/google.png'),
    forgot_password_icon: require ('./images/icons/forgot_password_icon.png'),
    username_icon: require ('./images/icons/username_icon.png'),
    activation_code_icon: require ('./images/icons/activation_code_icon.png'),
    set_password_icon: require ('./images/icons/set_password_icon.png'),
    anniversary: require ('./images/categories/anniversary.png'),
    art: require ('./images/categories/art.png'),
    ceremony: require ('./images/categories/ceremony.png'),
    business: require ('./images/categories/business.png'),
    celebration: require ('./images/categories/celebration.png'),
    culture: require ('./images/categories/culture.png'),
    eat_drink: require ('./images/categories/eat_drink.png'),
    education: require ('./images/categories/education.png'),
    fun: require ('./images/categories/fun.png'),
    exhibition: require ('./images/categories/exhibition.png'),
    fuar: require ('./images/categories/fuar.png'),
    holiday: require ('./images/categories/holiday.png'),
    interior: require ('./images/categories/interior.png'),
    outdoor: require ('./images/categories/outdoor.png'),
    opening: require ('./images/categories/opening.png'),
    religion: require ('./images/categories/religion.png'),
    scientific: require ('./images/categories/scientific.png'),
    conference: require ('./images/categories/conference.png'),
    show: require ('./images/categories/show.png'),
    social: require ('./images/categories/social.png'),
    sport: require ('./images/categories/sport.png'),
    mention: require ('./images/categories/mention.png'),
    race: require ('./images/categories/race.png'),
    other: require ('./images/categories/other.png'),
    slogan_1: require ('./images/slide_images/1.jpeg'),
    slogan_2: require ('./images/slide_images/2.jpeg'),
    slogan_3: require ('./images/slide_images/3.jpeg'),
    slogan_4: require ('./images/slide_images/4.jpeg'),
    slogan_5: require ('./images/slide_images/5.jpeg'),
    slogan_6: require ('./images/slide_images/6.jpeg'),
    speaker: require ('./images/speaker.png'),
    qr: require ('./images/qr.png'),
    vidipassbank_colourful_navbar: require ('./images/vidipassbank_colourful.png'),
    vidipass_vp_icon: require ('./images/thumb/vidipass_vp_icon.jpg'),
    vidipass_200m: require ('./images/thumb/vidipass_200m.jpg'),
    vidipass_black_01: require ('./images/thumb/vidipass_black_01.png'),
    vidipass_black_02: require ('./images/thumb/vidipass_black_02.png'),
    vidipassbank_colourful_logo: require ('./images/thumb/vidipassbank_colourful_logo.png'),
    vidipassbank_colourful: require ('./images/thumb/vidipassbank_colourful.png'),
    vidipassbank: require ('./images/thumb/vidipassbank.png'),
    vidipass_qr_200px: require ('./images/thumb/vidipass_qr_200px.png'),
    ok_icon: require ('./images/icons/ok_icon.png'),
    ok_icon_2: require ('./images/icons/ok_icon_2.png'),
    vidipass_partner: require('./images/thumb/VidipassPartner.png'),
    vidipass_person: require('./images/thumb/vidipass_person.png'),
    vidipass_key: require('./images/thumb/vidipass_key.png'),
    vidipass_ad1: require('./images/thumb/Vidipass_Ad_EN_Pass_is_More_Freedom_in_the_Ticket_1080x1920.png'),
    vidipass_ad2: require('./images/thumb/Vidipass_Ad_TR_Pass_Doğrusu_Bilette_Özgürlük_900x1600.png'),
    vidipass_ad3: require('./images/thumb/Vidipass_Ad_EN_Pass_is_More_Innovation_in_Ticket_1080x1920.png'),
    vidipass_ad4: require('./images/thumb/Vidipass_Ad_TR_Pass_Doğrusu_Bilette_Yenilik_1080x1920.png'),
    vidipass_ad5: require('./images/thumb/Vidipass_Ad_EN_Pass_is_More_Peace_in_Ticket_1080x1920.png'),
    vidipass_ad6: require('./images/thumb/Vidipass_Ad_TR_Pass_Doğrusu_Bilette_Huzur_900x1600.png'),
    vidipass_ad7: require('./images/thumb/Vidipass_Ad_EN_For_Activities_1080x1920.png'),
    vidipass_ad8: require('./images/thumb/Vidipass_Ad_TR_Aktiviteler_için_900x1600.png'),
    vidipass_ad9: require('./images/thumb/Vidipass_Ad_TR_yeni_nesil_biletleme_2835x5669.png'),
    vidipass_logo_new_generation_ticketing: require('./images/thumb/Vidipass_TR_yeni_nesil_biletleme.png'),
    vidipass_qr_icon: require ('./images/vidipass_qr_icon.jpg'),
    vidipass_for_ticket_en: require('./images/thumb/Vidipass_EN_For_Ticket.png'),
    vidipass_for_ticket_tr: require('./images/thumb/Vidipass_TR_Bilet_Icin.png'),
    slide_tr_01: require('./images/slide_images/slide_tr_01.png'),
    slide_tr_02: require('./images/slide_images/slide_tr_02.png'),
    slide_tr_03: require('./images/slide_images/slide_tr_03.png'),
    activity_7: require('./images/slide_images/activity_7.png'),
    activity_9: require('./images/slide_images/activity_9.png'),
    activity_10: require('./images/slide_images/activity_10.png'),
    activity_11: require('./images/slide_images/activity_11.png'),
    activity_13: require('./images/slide_images/activity_13.png'),
    activity_14: require('./images/slide_images/activity_14.png'),
    activity_15: require('./images/slide_images/activity_15.png'),
    activity_16: require('./images/slide_images/activity_16.png'),
    activity_17: require('./images/slide_images/activity_17.png'),
    activity_18: require('./images/slide_images/activity_18.png'),
    email_icon: require ('./images/icons/email_icon.png'),
    phone_icon: require ('./images/icons/phone_icon.png'),
    ataturk: require ('./images/icons/ataturk.png'),
    actividi_logo: require ('./images/icons/actividi_logo.png'),
    instagram_logo: require ('./images/icons/instagram_logo.png'),
    whatsapp_logo: require ('./images/icons/whatsapp_logo.png'),
    vidipass_for_ticket_tr_framed: require('./images/thumb/Vidipass_TR_bilet_icin_framed.png'),
    vidipass_for_ticket_en_framed: require('./images/thumb/Vidipass_EN_For_Ticket_framed.png'),
    vidipass_for_ticket:require('./images/vidipass_for_ticket.png'),
    app_gallery:require('./images/icons/app_gallery.png'),
    app_store:require('./images/icons/app_store.png'),
    google_play:require('./images/icons/google_play.png'),
    apple_icon_logo: require ('./images/icons/appleicon.png'),
    google_icon_logo: require ('./images/icons/googleicon.png'),
    youtube_logo: require ('./images/icons/youtube_logo.png'),
    tiktok_logo: require ('./images/icons/tiktok_logo.png'),
    background: require ('./images/background.png'),
    top_corner: require ('./images/top_corner.png'),
    bottom_corner: require ('./images/bottom_corner.png'),
    Vidipass_TR_bilet_icin_white: require ('./images/Vidipass_TR_bilet_icin_white.png'),
    error_man: require ('./images/error_man.png'),
    secured: require ('./images/icons/secured.png'),
    etbis: require ('./images/icons/etbis.png'),
    x_logo: require ('./images/icons/x_logo.png'),
    logo_1: require ('./images/support_logo_1.png'),
    logo_2: require ('./images/support_logo_2.png'),
    logo_3: require ('./images/support_logo_3.png'),
    logo_4: require ('./images/support_logo_4.png'),
    logo_5: require ('./images/support_logo_5.png'),
    curtain: require ('./images/icons/curtain.png'),
    login: require ('./images/icons/login.png'),
    logout: require ('./images/icons/logout.png'),
    panel: require ('./images/icons/panel.png'),
    ticket: require ('./images/icons/ticket.png'),
    wallet: require ('./images/icons/wallet.png'),
    notification: require ('./images/icons/notification.png'),
    profile: require ('./images/icons/profile.png'),
    basket: require ('./images/icons/basket.png'),
    calendar: require ('./images/icons/calendar.png'),
    city: require ('./images/icons/city.png'),
    search: require ('./images/icons/search.png')
}
export default images