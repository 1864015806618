import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import { _ } from "../config/languages/i18n";
import * as Popover from "@radix-ui/react-popover";
import globals from "../config/globals/globals";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import Multiselect from "multiselect-react-dropdown";
import { getCities } from "../config/cities/cities";

const initialSeats = (rows, columns, rowLabels) => {
  let seats = [];
  for (let i = 0; i < rows; i++) {
    let row = [];
    for (let j = 0; j < columns; j++) {
      row.push({
        label: "", // Başlangıçta boş
        categoryName: "",
        status: "empty", // Başlangıçta empty
        type: "",
        position_type: "",
        related_number: "",
        color: "",
        priceTypes: [],
      });
    }
    seats.push(row);
  }
  return seats;
};

const EditStages = () => {
  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);
  const [seats, setSeats] = useState(initialSeats(rows, columns));
  const [rowLabels, setRowLabels] = useState(
    []
  );
  const [hallName, setHallName] = useState("");
  const [hallCapacity, setHallCapacity] = useState("");
  const [hallAddress, setHallAddress] = useState("");
  const [hallDescription, setHallDescription] = useState("");
  const [hallLat, setHallLat] = useState("");
  const [hallLng, setHallLng] = useState("");
  const [hallPlan, setHallPlan] = useState([])
  const formData = new FormData();
  const [token, setToken] = useState();
  const HTTP = axios.create({ baseURL: globals.apiUrl });
  const [loading, setLoading] = useState(false);

  const [categoryType, setCategoryType] = useState("seat"); // Default: Ayakta
  const [categoryName, setCategoryName] = useState("");
  const [color, setColor] = useState("#000000");
  const [categoriesSeat, setCategoriesSeat] = useState([]);
  const [categoriesUnseat, setCategoriesUnseat] = useState([]);
  const [editMode, setEditMode] = useState(null); // Düzenleme Modu
  const [originalType, setOriginalType] = useState(""); // Düzenleme için orijinal kategori tipi
  const [saloonId, setSaloonId] = useState("")
  const cities = getCities();
  const [city, setCity] = useState([])
  

  const headerRef = useRef(null); // <h2> kısmına referans

  const combinedData = seats.map((row, rowIndex) => {
    return {
      rowLabel: rowLabels[rowIndex],
      seats: row,
    };
  });

  const generateRelatedNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000).toString();
  };

  useEffect(() => {
    const newSeats = initialSeats(rows, columns);
    const updatedSeats = newSeats.map((row, rowIndex) =>
      row.map((seat, colIndex) => {
        if (seats[rowIndex] && seats[rowIndex][colIndex]) {
          return { ...seat, ...seats[rowIndex][colIndex] };
        }
        return seat;
      })
    );

    setSeats(updatedSeats);
    
  }, [rows, columns]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser) {
      if (getuser.result) {
        if (getuser.token) {
          setToken(getuser.token);
        }
      }
    }
  }, []);

  
  const handleReserveSeats = () => {
    setLoading(true);
    formData.append("id",saloonId)
    formData.append("title", hallName);
    formData.append("capacity", hallCapacity);
    formData.append("hall_address", hallAddress);
    formData.append("hall_description", hallDescription);
    formData.append("hall_lat", hallLat);
    formData.append("hall_lon", hallLng);
    formData.append("city_id", city[0]);
    formData.append("seats_plan", JSON.stringify(combinedData));

    HTTP.post("/editHallPlan", formData, {
      headers: { Authorization: `bearer ${token}` },
    }).then((res) => {
      setLoading(false);
      if (res.data.result) {
        setHallName(res.data.hallPlan.title)
        setHallCapacity(res.data.hallPlan.capacity)
        setHallAddress(res.data.hallPlan.hall_address)
        setHallDescription(res.data.hallPlan.hall_description)
        setHallLat(res.data.hallPlan.hall_lat)
        setHallLng(res.data.hallPlan.hall_lon)
        setHallPlan(res.data.hallPlan.hall_row)
        toast(_("successful"), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          closeButton: false,
        });
      } else {
        toast(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          closeButton: false,
          type: "error",
        });
      }
    });
  };


useEffect(()=>{
  setSaloonId(window.location.href.split("/")[5])
},[])

 

  useEffect(()=>{
    setLoading(true)
    HTTP.get(`/halls/get/${saloonId}`,{
        headers: { Authorization: `bearer ${token}` },
      }).then((res) => {
       if(res.data.result){
        setHallName(res.data.hallPlan.title)
        setHallCapacity(res.data.hallPlan.capacity)
        setHallAddress(res.data.hallPlan.hall_address)
        setHallDescription(res.data.hallPlan.hall_description)
        setHallLat(res.data.hallPlan.hall_lat)
        setHallLng(res.data.hallPlan.hall_lon)
        setHallPlan(res.data.hallPlan.hall_row)
        setLoading(false)
        console.log(res)
       }
      });

  },[token,saloonId])
console.log(city)

  const onSelect = (selectedList, selectedItem) => {
		setCity([...city, selectedItem.key])
	}

	const onRemove = (selectedList, removedItem) => {
		const myRemove = city.filter(function (item) {
			return item !== removedItem.key
		})
		setCity(myRemove)
	}



  const handleAssignSeat = (
    rowIndex,
    colIndex,
    categoryName,
    categoryColor,
    type
  ) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];

    // Koltuk bilgilerini güncelle
    seat.label = getUpdatedLabel(rowIndex, colIndex); // Otomatik etiketleme
    seat.status = "seat";
    seat.categoryName = categoryName;
    seat.color = categoryColor;
    seat.type = type;

    // Eğer koltuk tipi "love" ise sağındaki koltuğu ayarla
    if (type === "love") {
      if (colIndex + 1 < updatedSeats[rowIndex].length) {
        const nextSeat = updatedSeats[rowIndex][colIndex + 1];

        nextSeat.label = getUpdatedLabel(rowIndex, colIndex + 1); // Sağdaki koltuk için etiketleme
        nextSeat.status = "seat";
        nextSeat.categoryName = categoryName;
        nextSeat.color = categoryColor;
        nextSeat.type = type;

        const relatedNumber = generateRelatedNumber(); // Aynı related number
        seat.related_number = relatedNumber;
        seat.position_type = "left";
        nextSeat.related_number = relatedNumber;
        nextSeat.position_type = "right";

        updatedSeats[rowIndex][colIndex + 1] = nextSeat;
      }
    } else if (seat.related_number) {
      // Eğer tipi "love" değilse ve önceden "love" tipindeyse ilgili koltukları boşalt
      updatedSeats.forEach((row, rIndex) => {
        row.forEach((s, cIndex) => {
          if (s.related_number === seat.related_number && s !== seat) {
            s.label = "";
            s.status = "empty";
            s.categoryName = "";
            s.color = "";
            s.type = "";
            s.related_number = "";
            s.position_type = "";

            updatedSeats[rIndex][cIndex] = s;
          }
        });
      });

      // Mevcut koltuğun `related_number`ını da temizle
      seat.related_number = "";
      seat.position_type = "";
    }

    // Koltuğu güncelle
    updatedSeats[rowIndex][colIndex] = seat;

    setSeats(updatedSeats);
  };

  const handleLabelChange = (rowIndex, colIndex, newLabel) => {
    const updatedSeats = [...seats];
    updatedSeats[rowIndex][colIndex].label = newLabel;
    setSeats(updatedSeats);
  };

  const handleRowLabelChange = (rowIndex, newLabel) => {
    const updatedLabels = [...rowLabels];
    updatedLabels[rowIndex] = newLabel;

    // Satır adlarını sıralı olarak güncelle
    for (let i = rowIndex + 1; i < updatedLabels.length; i++) {
      const previousLabel = updatedLabels[i - 1];
      if (previousLabel) {
        const nextLabel = String.fromCharCode(previousLabel.charCodeAt(0) + 1);
        updatedLabels[i] = nextLabel;
      } else {
        updatedLabels[i] = ""; // Boş etiket varsa, sonraki etiketleri güncelleme
      }
    }

    setRowLabels(updatedLabels);

    // Koltuk etiketlerini güncelle
    const updatedSeats = seats.map((row, index) =>
      row.map((seat, colIndex) => {
        if (index === rowIndex || index > rowIndex) {
          // Eğer koltuk durumu 'selected' ise, etiket güncelle
          const label =
            updatedLabels[index] && seat.status === "seat"
              ? getUpdatedLabel(index, colIndex, updatedLabels[index])
              : "";
          return { ...seat, label };
        }
        return seat;
      })
    );

    setSeats(updatedSeats);
  };

  const getUpdatedLabel = (rowIndex, colIndex) => {
    const rowSeats = seats[rowIndex];
    const selectedSeats = rowSeats
      .slice(0, colIndex + 1)
      .filter((seat) => seat.status === "seat");
    return rowLabels[rowIndex]
      ? `${rowLabels[rowIndex]}${selectedSeats.length}`
      : "";
  };

  useEffect(() => {
    const updatedSeats = seats.map((row, rowIndex) =>
      row.map((seat, colIndex) => {
        return {
          ...seat,
          label: seat.isStage ? "" : getUpdatedLabel(rowIndex, colIndex),
        };
      })
    );
    setSeats(updatedSeats);
  }, [rowLabels]); // rowLabels değiştiğinde çalışacak

  useEffect(() => {
    const updatedSeats = seats.map((row, rowIndex) =>
      row.map((seat, colIndex) => {
        return {
          ...seat,
          // label: seat.isStanding ? "" : getUpdatedLabel(rowIndex, colIndex),
        };
      })
    );
    setSeats(updatedSeats);
  }, [rowLabels]); // rowLabels değiştiğinde çalışacak

  useEffect(() => {
    const updatedSeats = seats.map((row, rowIndex) =>
      row.map((seat, colIndex) => {
        if (seat.status === "seat") {
          return {
            ...seat,
            // label: getUpdatedLabel(rowIndex, colIndex),
          };
        }
        return seat;
      })
    );
    setSeats(updatedSeats);
  }, [
    seats
      .map((row) => row.map((seat) => seat.status))
      .flat()
      .join(","),
    rowLabels,
  ]); // Sadece 'status' alanını izler

  const handleStageSelection = (rowIndex, colIndex) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];

    // Eğer koltuk "love" tipi ise ilgili diğer koltukları temizle
    if (seat.related_number) {
      // Eğer tipi "love" değilse ve önceden "love" tipindeyse ilgili koltukları boşalt
      updatedSeats.forEach((row, rIndex) => {
        row.forEach((s, cIndex) => {
          if (s.related_number === seat.related_number && s !== seat) {
            s.label = "";
            s.status = "empty";
            s.categoryName = "";
            s.color = "";
            s.type = "";
            s.related_number = "";
            s.position_type = "";

            updatedSeats[rIndex][cIndex] = s;
          }
        });
      });

      // Mevcut koltuğun `related_number`ını da temizle
      seat.related_number = "";
      seat.position_type = "";
    }

    // Koltuğu sahne olarak ayarla
    seat.label = "";
    seat.status = "stage";
    seat.color = "#2E81D2"; // Sahne rengi
    seat.type = "";
    seat.related_number = ""; // Sahne olduğu için bağlantılı numara yok
    seat.position_type = "";

    updatedSeats[rowIndex][colIndex] = seat;
    setSeats(updatedSeats);
  };

  const handleStandingSelection = (
    rowIndex,
    colIndex,
    categoryName,
    categoryColor
  ) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];

    // Eğer koltuk "love" tipi ise ilgili diğer koltukları temizle
    if (seat.related_number) {
      // Eğer tipi "love" değilse ve önceden "love" tipindeyse ilgili koltukları boşalt
      updatedSeats.forEach((row, rIndex) => {
        row.forEach((s, cIndex) => {
          if (s.related_number === seat.related_number && s !== seat) {
            s.label = "";
            s.status = "empty";
            s.categoryName = "";
            s.color = "";
            s.type = "";
            s.related_number = "";
            s.position_type = "";

            updatedSeats[rIndex][cIndex] = s;
          }
        });
      });

      // Mevcut koltuğun `related_number`ını da temizle
      seat.related_number = "";
      seat.position_type = "";
    }

    // Koltuğu "standing" olarak ayarla
    seat.label = "";
    seat.status = "standing";
    seat.categoryName = categoryName; // Kategori adı
    seat.color = categoryColor; // Kategori rengi
    seat.type = "";
    seat.related_number = ""; // Standing olduğu için bağlantılı numara yok
    seat.position_type = "";

    updatedSeats[rowIndex][colIndex] = seat;
    setSeats(updatedSeats);
  };

  const handleEmptySelection = (rowIndex, colIndex) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex][colIndex];

    // Eğer koltuk "love" tipi ise ilgili diğer koltukları temizle
    if (seat.related_number) {
      updatedSeats.forEach((row, rIndex) => {
        row.forEach((s, cIndex) => {
          if (s.related_number === seat.related_number) {
            s.label = "";
            s.categoryName = "";
            s.status = "empty";
            s.type = "";
            s.color = "";
            s.related_number = "";
            s.position_type = "";
            updatedSeats[rIndex][cIndex] = s;
          }
        });
      });
    }

    // Koltuğu "empty" olarak ayarla
    seat.label = "";
    seat.categoryName = "";
    seat.status = "empty";
    seat.type = "";
    seat.color = "";
    seat.related_number = ""; // Bağlantılı numara sıfırlanır
    seat.position_type = "";

    updatedSeats[rowIndex][colIndex] = seat;
    setSeats(updatedSeats);
  };


  const [originalRowLabels, setOriginalRowLabels] = useState([...rowLabels]);
  const [isReversed, setIsReversed] = useState(false); // Sıralamanın ters olup olmadığını kontrol eder
  const handleSortToggle = () => {
    const reversedLabels = [...originalRowLabels].reverse();
    setRowLabels(reversedLabels);

    setIsReversed(!isReversed); // Durumu değiştir
  };

  useEffect(() => {
    setOriginalRowLabels([...rowLabels]);
  }, [rowLabels]);

  const handleRowStatusChange = (
    rowIndex,
    status,
    name,
    category_color,
    seat_type
  ) => {
    const updatedSeats = seats.map((row, index) =>
      index === rowIndex
        ? row.map((seat) => {
            // Renkleri durum değerine göre ayarlayın
            let color = "";
            let categoryName = "";
            let type = "";
            let related_number = ""; // Bağlantılı numara sıfırlanır
            let position_type = "";
            if (status === "seat") {
              color = category_color; // Selected durumu için renk
              categoryName = name;
              type = "normal";
              related_number = "";
              position_type = "";
            } else if (status === "stage") {
              color = "#2E81D2"; // Stage durumu için renk
              related_number = "";
              position_type = "";
            } else if (status === "standing") {
              color = category_color; // Stage durumu için renk
              categoryName = name;
              related_number = "";
              position_type = "";
            }

            return {
              ...seat,
              status,
              label: status === "seat" ? seat.label : "", // Eğer status 'selected' değilse label'ı boş yap
              color, // Renk değerini ekleyin
              categoryName,
              type,
              related_number,
              position_type,
            };
          })
        : row
    );
    setSeats(updatedSeats);
  };
  const handleLatChange = (e) => {
    const value = e.target.value;
    // Eksi işareti kontrolü ve geçerli sayı kontrolü
    if (
      value === "-" ||
      value === "" ||
      (!isNaN(parseFloat(value)) &&
        parseFloat(value) >= -90 &&
        parseFloat(value) <= 90)
    ) {
      setHallLat(value);
    }
  };

  const handleLngChange = (e) => {
    const value = e.target.value;
    // Eksi işareti kontrolü ve geçerli sayı kontrolü
    if (
      value === "-" ||
      value === "" ||
      (!isNaN(parseFloat(value)) &&
        parseFloat(value) >= -180 &&
        parseFloat(value) <= 180)
    ) {
      setHallLng(value);
    }
  };

  const handleLatBlur = () => {
    // Kullanıcı inputtan çıktığında stringi sayıya dönüştür
    if (!isNaN(parseFloat(hallLat))) {
      setHallLat(parseFloat(hallLat));
    }
  };

  const handleLngBlur = () => {
    // Kullanıcı inputtan çıktığında stringi sayıya dönüştür
    if (!isNaN(parseFloat(hallLng))) {
      setHallLng(parseFloat(hallLng));
    }
  };

  const resetForm = () => {
    setCategoryName("");
    setColor("#000000");
    setCategoryType("seat");
    setEditMode(null);
    setOriginalType("");
  };

  const addCategory = () => {
    const newCategory = {
      categoryName,
      color,
      priceTypes: [], // Koltuklu ise boş bir dizi
      type: categoryType,
    };

    let oldCategoryName = "";
    if (editMode !== null) {
      oldCategoryName =
        originalType === "seat"
          ? categoriesSeat[editMode].categoryName
          : categoriesUnseat[editMode].categoryName;

      // Mevcut kategoriyi güncelle
      if (originalType === "seat" && categoryType === "seat") {
        const updated = [...categoriesSeat];
        updated[editMode] = newCategory;
        setCategoriesSeat(updated);
      } else if (originalType === "unseat" && categoryType === "unseat") {
        const updated = [...categoriesUnseat];
        updated[editMode] = newCategory;
        setCategoriesUnseat(updated);
      } else {
        // Kategori tipi değiştiyse
        if (originalType === "seat") {
          const updatedSeat = [...categoriesSeat];
          updatedSeat.splice(editMode, 1);
          setCategoriesSeat(updatedSeat);
          setCategoriesUnseat([...categoriesUnseat, newCategory]);
        } else {
          const updatedUnseat = [...categoriesUnseat];
          updatedUnseat.splice(editMode, 1);
          setCategoriesUnseat(updatedUnseat);
          setCategoriesSeat([...categoriesSeat, newCategory]);
        }
      }
    } else {
      // Yeni kategori ekleme
      if (categoryType === "seat") {
        setCategoriesSeat([...categoriesSeat, newCategory]);
      } else {
        setCategoriesUnseat([...categoriesUnseat, newCategory]);
      }
    }

    // Koltukları güncelle (sadece düzenlenmiş kategoriler için)
    if (oldCategoryName) {
      const updatedSeats = seats.map((row) =>
        row.map((seat) =>
          seat.categoryName === oldCategoryName
            ? {
                ...seat,
                categoryName: newCategory.categoryName,
                color: newCategory.color,
              } // Renk ve adı güncelle
            : seat
        )
      );
      setSeats(updatedSeats);
    }

    resetForm();
  };

  const deleteCategory = (index, type) => {
    let categoryToDelete;

    if (type === "seat") {
      const updated = [...categoriesSeat];
      categoryToDelete = updated[index].categoryName;
      updated.splice(index, 1);
      setCategoriesSeat(updated);
    } else {
      const updated = [...categoriesUnseat];
      categoryToDelete = updated[index].categoryName;
      updated.splice(index, 1);
      setCategoriesUnseat(updated);
    }

    // Koltuklardan silinen kategoriyi kaldır
    const updatedSeats = seats.map((row) =>
      row.map((seat) =>
        seat.categoryName === categoryToDelete
          ? { ...seat, categoryName: "", color: "", status: "empty", type: "", position_type: "", related_number:"" } // Kategori kaldırıldı
          : seat
      )
    );
    setSeats(updatedSeats);
    resetForm()
  };

  
  

  const editCategory = (index, type) => {
    const categoryToEdit =
      type === "seat" ? categoriesSeat[index] : categoriesUnseat[index];
    setCategoryName(categoryToEdit.categoryName);
    setColor(categoryToEdit.color);
    setCategoryType(categoryToEdit.type);
    setEditMode(index);
    setOriginalType(type); // Orijinal tipi sakla

    // Sayfayı <h2> başlığına kaydır
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {

    // RowLabels oluştur
    const initialRowLabels = hallPlan.map(row => row.row_label || '');
    setRowLabels(initialRowLabels);


    // Seats oluştur
    const initialSeats = hallPlan.map(row => 
      row.hall_seat.map(seat => ({
        label: seat.label,
        categoryName: seat.category_name,
        status: seat.status,
        type: seat.type,
        position_type: seat.position_type,
        related_number: seat.related_number,
        color: seat.color,
        priceTypes: seat.price_types,
      }))
    );
    setSeats(initialSeats);
  
    // Categories oluştur
    const seatCategories = [];
    const unseatCategories = [];
  
    hallPlan.forEach(row => {
      row.hall_seat.forEach(seat => {
        if (seat.category_name) {
          if (seat.status === 'seat' && !seatCategories.some(cat => cat.categoryName === seat.category_name)) {
            seatCategories.push({
              categoryName: seat.category_name,
              color: seat.color,
              priceTypes: seat.price_types || [],
              type: 'seat'
            });
          }
          if (seat.status === 'standing' && !unseatCategories.some(cat => cat.categoryName === seat.category_name)) {
            unseatCategories.push({
              categoryName: seat.category_name,
              color: seat.color,
              priceTypes: seat.price_types || [],
              type: 'standing'
            });
          }
        }
      });
    });
  
    setCategoriesSeat(seatCategories);
    setCategoriesUnseat(unseatCategories);
    // hallPlan'dan satır ve sütun sayısını belirle
    const initialRows = hallPlan.length;  // hallPlan'deki satır sayısı
    const initialColumns = hallPlan[0]?.hall_seat?.length || 0;  // İlk satırdaki koltuk sayısı

    // Rows ve Columns değerlerini güncelle
    setRows(initialRows);
    setColumns(initialColumns);
  
  }, [hallPlan]);
  
console.log(seats)
 
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="mt-5">
            <div className="container">
              <h3 className="homeText text-center">{_("edit_stage")}</h3>
              <div className="d-flex flex-wrap mb-4 col-sm-12 gap-5 border border-secondary-subtle p-2 rounded-2">
                <div className="col">
                  <label className="form-control-label text-muted">
                    {_("hall_name")}
                  </label>
                  <input
                  className="form-control"
                    label="Rows"
                    type="text"
                    value={hallName}
                    onChange={(e) => setHallName(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label className="form-control-label text-muted">
                    {_("hall_capacity")}
                  </label>
                  <input
                  className="form-control"
                    label="Rows"
                    type="number"
                    value={hallCapacity}
                    onChange={(e) => setHallCapacity(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap mb-4 col-sm-12 border border-secondary-subtle p-2 rounded-2">
                <label className="form-control-label text-muted">
                  {_("hall_address")}
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  value={hallAddress}
                  onChange={(e) => setHallAddress(e.target.value)}
                ></textarea>
              </div>
              <div className="d-flex flex-wrap mb-4 col-sm-12 border border-secondary-subtle p-2 rounded-2">
                <label className="form-control-label text-muted">
                  {_("city")}
                </label>
                <div className="col-sm-12 mb-3">
															<Multiselect
																className="bg-white"
																options={cities}
																displayValue="value"
																selectionLimit={1}
																avoidHighlightFirstOption
																emptyRecordMsg={_('no_city_search_criteria')}
																placeholder={_('please_select_city')}
																selectedValues={cities.key}
																onSelect={onSelect}
																onRemove={onRemove}
																style={{
																	searchBox: { // To change search box element look
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	inputField: { // To change input field position or margin
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	chips: { // To change css chips(Selected options)
																		backgroundColor: '#933CAC'
																	},
																	optionContainer: { // To change css for option container 
																		maxHeight: '150px', // Açılan kutunun maksimum yüksekliği
																		overflowY: 'auto', // Kaydırma çubuğu ekler
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	}
																}}
															/>
														</div>

                
              </div>
              <div className="d-flex flex-wrap mb-4 col-sm-12 border border-secondary-subtle p-2 rounded-2">
                <label className="form-control-label text-muted">
                  {_("hall_description")}
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  value={hallDescription}
                  onChange={(e) => setHallDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="d-flex flex-wrap mb-4 col-sm-12 gap-5 border border-secondary-subtle p-2 rounded-2">
                <div className="col">
                  <label className="form-control-label text-muted">
                    {_("lat")}
                  </label>
                  <input
                    className="form-control"
                    label="Rows"
                    type="number"
                    value={hallLat}
                    onChange={handleLatChange}
                    onBlur={handleLatBlur}
                   
                  />
                </div>
                <div className="col">
                  <label className="form-control-label text-muted">
                    {_("lon")}
                  </label>
                  <input
                    className="form-control"
                    label="Columns"
                    type="number"
                    value={hallLng}
                    onChange={handleLngChange}
                    onBlur={handleLngBlur}
                   
                  />
                </div>
              </div>

              <div className="mb-4 border border-secondary-subtle p-2 rounded-2">
                {/* Referans ataması */}
                <label
                  className="form-control-label text-muted"
                  ref={headerRef}
                >
                  {_("category_edit")}
                </label>
                <div className="card p-3 mb-4">
                  {
                    editMode === null &&
                    <div className="d-flex align-items-center justify-content-center mb-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-radio"
                        type="radio"
                        name="categoryType"
                        id="seatOption"
                        value="seat"
                        checked={categoryType === "seat"}
                        onChange={() => setCategoryType("seat")}
                      />
                      <label className="form-check-label" htmlFor="seatOption">
                      {_("seated")}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-radio"
                        type="radio"
                        name="categoryType"
                        id="unseatOption"
                        value="unseat"
                        checked={categoryType === "unseat"}
                        onChange={() => setCategoryType("unseat")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="unseatOption"
                      >
                        {_("standing")}
                      </label>
                    </div>
                  </div>
                  }
                 

                  <div className="d-flex justify-content-center gap-1 align-items-center mb-3">
                    <div>
                      <label className="form-control-label text-muted">
                        {_("category_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="form-control-label text-muted">
                      {_("color")}
                      </label>
                      <input
                        type="color"
                        className="form-control form-control-color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-color mt-3 px-4"
                      onClick={addCategory}
                      style={{ color: "#FFFFFF" }}
                      disabled={!categoryName}
                    >
                      {editMode !== null
                        ? _("update")
                        : _("save")}
                    </button>
                  </div>
                </div>

                {/* Koltuklu Kategoriler */}
                {categoriesSeat.length > 0 && (
                  <>
                    <label className="form-control-label text-muted">
                      {_("seated_categories")}
                    </label>
                    <div className="d-flex flex-wrap gap-3 border border-secondary-subtle p-2 rounded-2 justify-content-space-between mb-3">
                      {categoriesSeat.map((val, index) => {
                        return (
                          <>
                            <div
                              style={{
                                border: `2px solid ${val.color}`,
                                boxShadow: "0 0 10px 0 rgba(100,100,100,1)",
                              }}
                              key={index}
                              className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 rounded-2"
                            >
                              <div className="d-flex justify-content-start align-items-start mb-2">
                                <button
                                  className="btn btn-sm me-2"
                                  style={{
                                    backgroundColor: "#933cac",
                                    color: "#FFFFFF",
                                  }}
                                  onClick={() => editCategory(index, "seat")}
                                >
                                  <span class="bi bi-pencil-square" />
                                </button>
                                <button
                                  className="btn btn-sm"
                                  style={{
                                    backgroundColor: "#933cac",
                                    color: "#FFFFFF",
                                  }}
                                  onClick={() => deleteCategory(index, "seat")}
                                >
                                  <span class="bi bi-trash" />
                                </button>
                              </div>
                              <div className="text-start">
                                <small
                                  className="text-muted"
                                  style={{ fontSize: 12 }}
                                >
                                  {val.categoryName}
                                </small>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}

                {/* Ayakta Kategoriler */}
                {categoriesUnseat.length > 0 && (
                  <>
                    <label className="form-control-label text-muted">
                    {_("standing_categories")}
                    </label>
                    <div className="d-flex flex-wrap gap-3 border border-secondary-subtle p-2 rounded-2 justify-content-space-between mb-3">
                      {categoriesUnseat.map((val, index) => {
                        return (
                          <div
                            style={{
                              border: `2px solid ${val.color}`,
                              boxShadow: "0 0 10px 0 rgba(100,100,100,1)",
                            }}
                            key={index}
                            className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 rounded-2"
                          >
                            <div className="d-flex justify-content-start align-items-start mb-2">
                              <button
                                className="btn btn-sm me-2"
                                style={{
                                  backgroundColor: "#933cac",
                                  color: "#FFFFFF",
                                }}
                                onClick={() => editCategory(index, "unseat")}
                              >
                                <span class="bi bi-pencil-square" />
                              </button>
                              <button
                                className="btn btn-sm"
                                style={{
                                  backgroundColor: "#933cac",
                                  color: "#FFFFFF",
                                }}
                                onClick={() => deleteCategory(index, "unseat")}
                              >
                                <span class="bi bi-trash" />
                              </button>
                            </div>
                            <div className="text-start">
                              <small
                                className="text-muted"
                                style={{ fontSize: 12 }}
                              >
                                {val.categoryName}
                              </small>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex flex-wrap mb-4 col-sm-12 gap-5 border border-secondary-subtle p-2 rounded-2">
                <div className="col">
                  <label className="form-control-label text-muted">
                    {_("row_number")}
                  </label>
                  <input
                    label="Rows"
                    type="number"
                    value={rows}
                    onChange={(e) => setRows(parseInt(e.target.value) || 0)}
                  />
                </div>
                <div className="col">
                  <label className="form-control-label text-muted">
                    {_("col_number")}
                  </label>
                  <input
                    label="Columns"
                    type="number"
                    value={columns}
                    onChange={(e) => setColumns(parseInt(e.target.value) || 0)}
                  />
                </div>
              </div>
            </div>

            <div
              id="seat-map"
              className="seat-map border border-secondary-subtle p-2 rounded-2"
            >
              <button
                style={{ backgroundColor: "#933CAC" }}
                className="btn btn-xl sort-button"
                onClick={handleSortToggle}
              >
                {isReversed ? (
                  <span
                    style={{ color: "#FFFFFF" }}
                    className="fas fa-arrow-down-short-wide d-flex align-self-center justify-content-center"
                  />
                ) : (
                  <span
                    style={{ color: "#FFFFFF" }}
                    className="fas fa-arrow-up-short-wide d-flex align-self-center justify-content-center"
                  />
                )}
              </button>
              {seats.map((row, rowIndex) => (
                <div key={rowIndex} className="seat-row">
                  <Popover.Root key={rowIndex}>
                    <Popover.Trigger asChild>
                      <div className="seat-row-label">
                        <input
                          type="text"
                          value={rowLabels[rowIndex]}
                          onChange={(e) =>
                            handleRowLabelChange(rowIndex, e.target.value)
                          }
                          className="seat-label"
                        />
                      </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        className="PopoverContent border border-primary-subtle"
                        sideOffset={5}
                        style={{
                          position: "relative", // Popover.Content'in konumunu relative yapıyoruz ki close butonunu yerleştirebilelim
                        }}
                      >
                        <div
                          style={{
                            maxHeight: "300px", // İçeriğin yüksekliğini sınırlayalım
                            overflowY: "auto", // Dikeyde kaydırma ekleyelim
                            marginTop: "10px",
                          }}
                        >
                          <div className="select-container d-flex row justify-content-center gap-2">
                            {
                              <>
                                {categoriesSeat.map((category, index) => (
                                  <button
                                    key={index}
                                    style={{
                                      backgroundColor: category.color,
                                    }}
                                    className="btn btn-xl button-fa-size"
                                    onClick={() =>
                                      handleRowStatusChange(
                                        rowIndex,
                                        "seat",
                                        category.categoryName,
                                        category.color
                                      )
                                    }
                                  >
                                    <span
                                      style={{ color: "#FFFFFF" }}
                                      className="fas fa-chair icon-fa-size"
                                    />
                                    {category.categoryName}{" "}
                                    {/* Kategori adını yazdır */}
                                  </button>
                                ))}
                              </>
                            }
                            <button
                              style={{ backgroundColor: "#2E81D2" }}
                              className="btn btn-xl button-fa-size"
                              onClick={() =>
                                handleRowStatusChange(rowIndex, "stage")
                              }
                            >
                              <span
                                style={{ color: "#FFFFFF" }}
                                className="fas fa-theater-masks icon-fa-size"
                              />
                            </button>

                            {
                              <>
                                {categoriesUnseat.map((category, index) => (
                                  <button
                                    key={index}
                                    style={{
                                      backgroundColor: category.color,
                                    }}
                                    className="btn btn-xl button-fa-size"
                                    onClick={() =>
                                      handleRowStatusChange(
                                        rowIndex,
                                        "standing",
                                        category.categoryName,
                                        category.color
                                      )
                                    }
                                  >
                                    <span
                                      style={{ color: "#FFFFFF" }}
                                      className="fas fa-person icon-fa-size"
                                    />
                                    {category.categoryName}{" "}
                                    {/* Kategori adını yazdır */}
                                  </button>
                                ))}
                              </>
                            }

                            <button
                              style={{ backgroundColor: "#000000" }}
                              className="btn btn-xl button-fa-size"
                              onClick={() =>
                                handleRowStatusChange(rowIndex, "empty")
                              }
                            >
                              <span
                                style={{ color: "#FFFFFF" }}
                                className="fas fa-ban icon-fa-size"
                              />
                            </button>
                          </div>
                        </div>
                        <Popover.Close className="bi bi-x-circle-fill PopoverClose" />

                        <Popover.Arrow className="PopoverArrow" />
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>

                  {row.map((seat, colIndex) => {
                    const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                    const showLabel = seat.status !== "empty"; // Sadece kategori atanmış koltuklar için label göster
                    const isEmpty = seat.status === "empty";
                    const isStanding = seat.status === "standing";
                    const category_name = seat.categoryName;
              
                    return (
                      <Popover.Root key={colIndex}>
                        <Popover.Trigger asChild>
                          <div
                            className={`seat ${seat.status} ${
                              isStage ? "stage-seat" : ""
                            } ${seat.position_type==="left" && "leftLoveSeat"} 
                            ${seat.position_type==="right" && "rightLoveSeat"} 
                            ${seat.type==="disable" && "disableSeat"}
                            ${seat.type==="normal" && "normalSeat"}
                            `}
                            style={{
                              backgroundColor: isStage ? "#2E81D2" : seat.color,
                            }}
                            onClick={() => ""}
                          >
                            {/* Label'ı sadece boş değilse ve koltuğa kategori atanmışsa göster */}
                            {showLabel && (
                              <input
                                type="text"
                                value={seat.label}
                                onChange={(e) =>
                                  handleLabelChange(
                                    rowIndex,
                                    colIndex,
                                    e.target.value
                                  )
                                }
                                className="seat-label"
                                disabled={isStage || isStanding}
                              />
                            )}
                          </div>
                        </Popover.Trigger>
                        <Popover.Portal>
                          <Popover.Content
                            className="PopoverContent border border-primary-subtle"
                            sideOffset={5}
                            style={{
                              position: "relative", // Popover.Content'in konumunu relative yapıyoruz ki close butonunu yerleştirebilelim
                            }}
                          >
                            <div
                              style={{
                                maxHeight: "300px", // İçeriğin yüksekliğini sınırlayalım
                                overflowY: "auto", // Dikeyde kaydırma ekleyelim
                                marginTop: "10px",
                              }}
                            >
                              <div className="select-container d-flex row justify-content-center gap-2">
                                {
                                  <>
                                    {categoriesSeat.map((category, index) => (
                                      <button
                                        key={index}
                                        style={{
                                          backgroundColor: category.color,
                                        }}
                                        className="btn btn-xl"
                                      >
                                        <span className="text-white d-flex justify-content-center align-items-center">
                                          {category.categoryName}
                                          {/* Kategori adını yazdır */}
                                        </span>

                                        <div className="row d-flex justify-content-center align-items-center">
                                          {(category.categoryName !==
                                            category_name ||
                                            seat.type != "normal") && (
                                            <button
                                              className="btn btn-sm col-4 mx-2"
                                              style={{
                                                backgroundColor: `${category.color}`,
                                                color: "#FFF",
                                                border: "3px solid #FFF",
                                                boxShadow:
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                                width: "40px",
                                              }}
                                              onClick={() =>
                                                handleAssignSeat(
                                                  rowIndex,
                                                  colIndex,
                                                  category.categoryName,
                                                  category.color,
                                                  "normal"
                                                )
                                              }
                                            >
                                              <span
                                                className="fas fa-chair"
                                                style={{ color: "#FFFFFF" }}
                                               
                                              ></span>
                                            </button>
                                          )}
                                          {
                                            // Sağdaki koltuğun durumunu kontrol et
                                            colIndex + 1 <
                                              seats[rowIndex].length &&
                                              (seats[rowIndex][colIndex + 1]
                                                .status === "empty" ||
                                                (seats[rowIndex][colIndex + 1]
                                                  .type === "love" &&
                                                  seats[rowIndex][colIndex + 1]
                                                    .categoryName !==
                                                    category.categoryName)) &&
                                              (category.categoryName !==
                                                category_name ||
                                                seat.type !== "love") && (
                                                <button
                                                  className="btn btn-sm col-4 mx-2"
                                                  style={{
                                                    backgroundColor: `${category.color}`,
                                                    color: "#FFF",
                                                    border: "3px solid #FFF",
                                                    boxShadow:
                                                      "0 0 10px 0 rgba(100,100,100,1)",
                                                    width: "40px",
                                                  }}
                                                  onClick={() =>
                                                    handleAssignSeat(
                                                      rowIndex,
                                                      colIndex,
                                                      category.categoryName,
                                                      category.color,
                                                      "love"
                                                    )
                                                  }
                                                >
                                                  <span
                                                    style={{ color: "#FFFFFF" }}
                                                    className="fa-solid fa-couch"
                                                   
                                                  ></span>
                                                </button>
                                              )
                                          }

                                          {(category.categoryName !==
                                            category_name ||
                                            seat.type != "disable") && (
                                            <button
                                              className="btn btn-sm col-4 mx-2"
                                              style={{
                                                backgroundColor: `${category.color}`,
                                                color: "#FFF",
                                                border: "3px solid #FFF",
                                                boxShadow:
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                                width: "40px",
                                              }}
                                              onClick={() =>
                                                handleAssignSeat(
                                                  rowIndex,
                                                  colIndex,
                                                  category.categoryName,
                                                  category.color,
                                                  "disable"
                                                )
                                              }
                                            >
                                              <span
                                                style={{ color: "#FFFFFF" }}
                                                className="fa-solid fa-wheelchair"
                                               
                                              ></span>
                                            </button>
                                          )}
                                        </div>
                                      </button>
                                    ))}
                                  </>
                                }
                                {categoriesUnseat.map(
                                  (category, index) =>
                                    category_name !== category.categoryName && (
                                      <button
                                        key={index}
                                        style={{
                                          backgroundColor: category.color,
                                        }}
                                        className="btn btn-xl"
                                        onClick={() =>
                                          handleStandingSelection(
                                            rowIndex,
                                            colIndex,
                                            category.categoryName,
                                            category.color
                                          )
                                        }
                                      >
                                        <span
                                          style={{ color: "#FFFFFF" }}
                                          className="fas fa-person icon-fa-size"
                                        />
                                        {category.categoryName}{" "}
                                        {/* Kategori adını yazdır */}
                                      </button>
                                    )
                                )}

                                {!isStage && (
                                  <button
                                    style={{ backgroundColor: "#2E81D2" }}
                                    className="btn btn-xl button-fa-size"
                                    onClick={() =>
                                      handleStageSelection(rowIndex, colIndex)
                                    }
                                  >
                                    <span
                                      style={{ color: "#FFFFFF" }}
                                      className="fas fa-theater-masks icon-fa-size"
                                    />
                                  </button>
                                )}

                                {!isEmpty && (
                                  <button
                                    style={{ backgroundColor: "#000000" }}
                                    className="btn btn-xl button-fa-size"
                                    onClick={() =>
                                      handleEmptySelection(rowIndex, colIndex)
                                    }
                                  >
                                    <span
                                      style={{ color: "#FFFFFF" }}
                                      className="fas fa-ban icon-fa-size"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>

                            <Popover.Close className="bi bi-x-circle-fill PopoverClose" />
                            <Popover.Arrow className="PopoverArrow" />
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root>
                    );
                  })}
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-center mt-3 mb-3">
              <button
                variant="contained"
                className="text-decoration-none btn btn-primary cardButton rounded-2 mt-3"
                onClick={handleReserveSeats}
              >
                {_("save")}
              </button>
            </div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default EditStages;
