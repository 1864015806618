import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import "react-phone-number-input/style.css";
import { useState, useEffect, useRef, useCallback } from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Modal from "react-bootstrap/Modal";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ShowMoreText from "react-show-more-text";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import Linkify from "linkify-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import Multiselect from "multiselect-react-dropdown";
import * as Popover from "@radix-ui/react-popover";

const Ticketing = () => {
  const timestamp = new Date();
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  const [activityName, setActivityName] = useState("");
  const [activityCategoryName, setActivityCategoryName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [description, setDescription] = useState("");
  const [number, setNumber] = useState();
  const [category, setCategory] = useState("null");
  const [seatCategory, setSeatCategory] = useState("null");
  const [activityId, setActivityId] = useState("");
  const [activityPhoto, setActivityPhoto] = useState("");
  const [token, setToken] = useState("");
  const [hall, setHall] = useState("");
  const [seat, setSeat] = useState("");
  const [seatName, setSeatName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [seatId, setSeatId] = useState("");
  const [price, setPrice] = useState("");
  const [ticket, setTicket] = useState("");
  const [priceCode, setPriceCode] = useState("");
  const [utc, setUTC] = useState("");
  const [counter, setCounter] = useState("");
  const HTTP = axios.create({ baseURL: globals.apiUrl });
  const [showSaloonModal, setShowSaloonModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [deleteCartModal, setDeleteCartModal] = useState(false);
  const [deleteTicketModal, setDeleteTicketModal] = useState(false);
  const [deleteTicketId, setDeleteTicketId] = useState(false);
  const [imgCreated, setImgCreated] = useState("");
  const [planURL, setPlanURL] = useState("");
  const [extra, setExtra] = useState("");
  const [password, setPassword] = useState("");
  const [place, setPlace] = useState("");
  const [ticketNameSurname, setTicketNameSurname] = useState("null");
  const divRef = useRef(null);
  const [term, setTerm] = useState(false);
  const [emailTerm, setEmailTerm] = useState(false);
  const [insurance, setInsurance] = useState(2);
  const [cancelInsuranceModal, setCancelInsuranceModal] = useState(false);
  const [priceStatus, setPriceStatus] = useState("");
  const [ticketNumber, setTicketNumber] = useState(0);
  const [serverTime, setServerTime] = useState("");
  const [urlModal, setUrlModal] = useState(false);
  const [copy, setCopy] = useState(false);
  const [startDateDay, setStartDateDay] = useState("");
  const [endDateDay, setEndDateDay] = useState("");
  const [mySeats, setMySeats] = useState([]);
  const [activityStatus, setActivityStatus] = useState("");
  const [addStatus, setAddStatus] = useState(0);
  const [cityName, setCityName] = useState("");
  const [activatedMail, setActivatedMail] = useState("");
  const [extraMail, setExtraMail] = useState("");
  const [activatedModal, setActivatedModal] = useState(false);
  const [codeStart, setCodeStart] = useState("");
  const [codeEnd, setCodeEnd] = useState("");
  const [metaDescription, setMetaDescripton] = useState("");
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [ip, setIp] = useState("");
  const [device, setDevice] = useState("");
  const [utcLog, setUtcLog] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [seatChart, setSeatChart] = useState([]);
  const [groupCenters, setGroupCenters] = useState({}); // Grupların merkezlerini saklar
  const [categoryStatus, setCategoryStatus] = useState("");
  const [prices, setPrices] = useState([]);
  const closeRef = useRef(null);
  const [planImage, setPlanImage] = useState("")
  
  const [popoverOpened, setPopoverOpened] = useState(false);
const popoverContentRef = useRef(null);

useEffect(() => {
  if (popoverOpened && popoverContentRef.current) {
    const popoverContent = popoverContentRef.current;

    // Önce en alta kaydır
    popoverContent.scrollTop = popoverContent.scrollHeight;

    // Animasyonlu şekilde yukarıya kaydır
    setTimeout(() => {
      if ('scrollBehavior' in document.documentElement.style) {
        popoverContent.scrollTo({
          top: 0,
          behavior: 'smooth' // Mobil ve destekleyen tarayıcılar için pürüzsüz kaydırma
        });
      } else {
        // Tarayıcı desteklemiyorsa fallback
        popoverContent.scrollTop = 0;
      }
    }, 100); // Gecikmeyi daha kısa tutabilirsiniz (mobilde hızlı yanıt için)
  }
}, [popoverOpened,popoverContentRef.current]);



  const handleClose = () => {
    if (closeRef.current) {
      closeRef.current.click(); // Popover.Close'u programatik olarak tetikle
    }
  };
  const togglePopover = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setDevice(navigator.userAgent);
  }, []);

  useEffect(() => {
    setUtcLog(new Date().getTimezoneOffset() / -60);
  }, []);

  useEffect(() => {
    HTTP.get("https://api.ipify.org?format=json").then((res) => {
      setIp(res.data.ip);
    });
  }, []);

  useEffect(() => {
    if (
      seat.length > 0 &&
      seatCategory !== "null" &&
      category !== "null" &&
      priceStatus == 1
    ) {
      setTicketNumber(1);
    } else {
      setTicketNumber(0);
    }
  }, [seat, seatCategory, mySeats, category]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser.result) {
      setToken(getuser.token);
      if (getuser.user.activated_email_extra) {
        setActivatedMail(getuser.user.email_extra);
      } else {
        setActivatedMail("");
      }
    }
    setLoading(true);
    setNumber(window.location.href.split("/")[5]);
    if (number) {
      HTTP.get(`/activities/get/${number}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setCityName(res.data.activity.city.name);
            setActivityName(res.data.activity.title);
            setStartDate(
              moment(res.data.activity.start_time).format("DD.MM.YYYY")
            );
            setStartTime(moment(res.data.activity.start_time).format("HH:mm"));
            setStartDateTime(res.data.activity.start_time);
            setEndDate(moment(res.data.activity.end_time).format("DD.MM.YYYY"));
            setEndTime(moment(res.data.activity.end_time).format("HH:mm"));
            setEndDateTime(res.data.activity.end_time);
            setDescription(res.data.activity.description);
            setMetaDescripton(res?.data?.activity?.meta_description);
            if (
              res.data.activity.meta_keywords !== null &&
              res.data.activity.meta_keywords !== ""
            ) {
              setMetaKeywords(JSON.parse(res.data.activity.meta_keywords));
            }
            setActivityCategoryName(res.data.activity.category.name);
            setActivityId(res.data.activity.id);
            setActivityPhoto(res.data.activity.photo_name);
            setPriceCode(res.data.activity.currency.tcmb_code);
            setToken();
            setHall(res.data.activity.hall);
            setSeatChart(res.data.activity.hall.row);
            setUTC(res.data.activity.utc);
            setImgCreated(res.data.activity.created_at);
            setPlanURL(res.data.activity.seats_plan);
            setPlanImage(res.data.activity.hall.plan_file_url)
            setExtra(res.data.activity.extra);
            setPlace(res.data.activity.place.title);
            setTicketNameSurname(getuser.user.username);
            setServerTime(res.data.time);
            setStartDateDay(
              moment(res.data.activity.start_time)
                .format("dddd")
                .toLocaleLowerCase()
            );
            setEndDateDay(
              moment(res.data.activity.end_time)
                .format("dddd")
                .toLocaleLowerCase()
            );
            setActivityStatus(res.data.activity.activity_status);
            setLoading(false);
           
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          } else {
            window.location.href = globals.url;
          }
        })
        .catch(setLoading(true));
    }
  }, [number]);

  const getGroupsByStatus = () => {
    const groups = {};

    seatChart.forEach((row, rowIndex) => {
      row.seat.forEach((seat, colIndex) => {
        const groupName = seat.group?.name || seat.status; // Eğer grubu varsa, grup ismi; yoksa status kullanacağız

        // Eğer grup daha önce tanımlanmadıysa, oluştur
        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        // Grup içindeki koltukları ekle
        groups[groupName].push({ rowIndex, colIndex });
      });
    });

    return groups;
  };


  const calculateGroupCenter = (group) => {
    if (!group || group.length === 0) return { center: null, positionType: 4 };

    const rows = group.map((seat) => seat.rowIndex);
    const cols = group.map((seat) => seat.colIndex);

    const minRow = Math.min(...rows);
    const maxRow = Math.max(...rows);
    const minCol = Math.min(...cols);
    const maxCol = Math.max(...cols);

    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;

    const closestSeat = group.reduce((closest, seat) => {
      const distance = Math.sqrt(
        Math.pow(seat.rowIndex - centerRow, 2) +
          Math.pow(seat.colIndex - centerCol, 2)
      );
      return closest === null || distance < closest.distance
        ? { seat, distance }
        : closest;
    }, null);

    const positionType =
      Number.isInteger(centerRow) && Number.isInteger(centerCol)
        ? 1
        : Number.isInteger(centerRow)
        ? 2
        : Number.isInteger(centerCol)
        ? 3
        : 4;

    return { center: closestSeat?.seat || null, positionType };
  };

  const calculateAllGroupCenters = () => {
    const groups = getGroupsByStatus(); // Tüm grupları al
    const newGroupCenters = {};

    Object.keys(groups).forEach((groupName) => {
      newGroupCenters[groupName] = calculateGroupCenter(groups[groupName]); // Her grup için merkezi hesapla
    });

    setGroupCenters(newGroupCenters); // Merkezi gruplarla birlikte güncelle
  };

  useEffect(() => {
    calculateAllGroupCenters();
  }, [seatChart]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser.result) {
      setToken(getuser.token);
    }
    setLoading(true);
    HTTP.get("/getCart", {
      headers: { Authorization: `bearer ${getuser.token}` },
    })
      .then((res) => {
        if (res.data.cart) {
          setTicket(res.data.cart.tickets);
          setCounter(res.data.cart.cartTime);
          localStorage.setItem(
            "basketsNumber",
            JSON.stringify(res.data.cart.tickets.length)
          );
          setLoading(false);
          
        }
      })
      .catch(setLoading(true));
  }, []);



  const frontRemoveTicket = (e) => {
    setDeleteTicketId(e);
    setDeleteTicketModal(true);
  };

  const onChangeTerm = (value) => {
    //setTerm(!term);
    if (!term) {
      setTerm(!term);
      setInsurance(1);
    } else {
      //setInsurance(0)
      // modal ac
      setCancelInsuranceModal(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
  }, [divRef.current]);

  
 





  

  // const removeTicket = (e) => {
  //   setTimeout(() => {
  //     window.scrollTo({
  //       behavior: "smooth",
  //       top: divRef.current.offsetTop,
  //     });
  //   }, 200);
  //   const ticket_id = e;
  //   const getuser = JSON.parse(localStorage.getItem("auth"));
  //   if (deleteTicketId) {
  //     HTTP.post(
  //       "/deleteTicket",
  //       { ticket_id, device, utcLog, ip },
  //       { headers: { Authorization: `bearer ${getuser.token}` } }
  //     )
  //       .then((res) => {
  //         if (res.data.result) {
  //           // setTicket(res.data.cart?.tickets)
  //           setSeatCategory("null");
  //           getTicket();
  //           setLoading(false);
  //           setTimeout(() => {
  //             window.scrollTo({
  //               behavior: "smooth",
  //               top: divRef.current.offsetTop,
  //             });
  //           }, 200);
  //           setAddStatus(2);
  //         } else {
  //           setLoading(true);
  //         }
  //       })
  //       .catch(setLoading(true));
  //   }
  // };

  useEffect(() => {
    if (ticket.length < 1) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [ticket]);

  const removeTicket = async (e) => {
    setLoading(true)
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    const ticket_id = e;
    const getuser = JSON.parse(localStorage.getItem("auth"));

    if (deleteTicketId) {
      // İlk bileti bul
      const primaryTicket = ticket.find((val) => val.id === ticket_id);

      // İlk biletin seat'inden related_id'yi al
      const relatedSeatId = primaryTicket?.seat?.related_id;

      // Related Seat ID ile ikinci bileti bul
      const secondaryTicket = ticket.find(
        (val) => val?.seat?.id === relatedSeatId
      );

      const secondaryTicketId = secondaryTicket?.id;

      // İlk bileti sil
      try {
        await HTTP.post(
          "/deleteTicket",
          { ticket_id, device, utcLog, ip },
          { headers: { Authorization: `bearer ${getuser.token}` } }
        );
      } catch (error) {
        console.error(error);
      }

      // İkinci bileti sil
      if (secondaryTicketId) {
        try {
          await HTTP.post(
            "/deleteTicket",
            { ticket_id: secondaryTicketId, device, utcLog, ip },
            { headers: { Authorization: `bearer ${getuser.token}` } }
          );
        } catch (error) {
          console.error(
            error
          );
        }
      }

      setSeatCategory("null");
      getTicket();
      getSeatChart(category,categoryStatus);
      setLoading(false);
   
      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top: divRef.current.offsetTop,
        });
      }, 200);
      setAddStatus(2);
    }
  };



  

  const removeCart = () => {
    setLoading(true)
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    HTTP.post(
      "/deleteCart",
      { ip, device, utcLog },
      { headers: { Authorization: `bearer ${getuser.token}` } }
    )
      .then((res) => {
        if (res.data) {
          // setTicket(res.data.cart?.tickets)
          getTicket();
          setCounter("");
          getSeatChart(category,categoryStatus);
          setLoading(false);
      
          setTimeout(() => {
            window.scrollTo({
              behavior: "smooth",
              top: divRef.current.offsetTop,
            });
          }, 200);
        }
      })
      .catch(setLoading(true));
  };

  const getSeat = (categoryId) => {
    
    const myArray = categoryId.split(",");
    const mySeat = myArray[0];
    const myGroup = myArray[1];
    const myPrice = myArray[2];
    const myStatus = myArray[3];
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setSeatCategory(categoryId);
    setSeatId(mySeat);
    setGroupId(myGroup);
    setPrice(myPrice);
    setPriceStatus(myStatus);
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0") {
      HTTP.get(`/activities/getUnsoldSeats/${myGroup}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setSeat(res.data.seats);
            setLoading(false);      
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  useEffect(() => {
    if(categoryStatus==="standing"){
      setTimeout(() => {
        if (prices?.length === 1) {
          const item = prices[0];
          const value = `${item.id},${item.group_id},${item.price},${item.status}`;
          setSeatCategory(value);
          getSeat(value);
        }
      }, 200);

    }
    
  }, [prices,seatCategory]);
  const saveSeat = (seat, related)=>{
    if (prices?.length === 1){
      const item = prices[0];
      const value = `${item.id},${item.group_id},${item.price},${item.status},${seat},${related && related}`;
      setSeatCategory(value);
    }
    
  }
  

  const getStanding = (categoryId,status) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0") {
      HTTP.get(`/activities/getUnsoldSeats/${categoryId}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setSeat(res.data.seats);
            setCategoryStatus(status);
            setCategory(categoryId);
            setLoading(false)
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  const getTicket = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    HTTP.get("/getCart", {
      headers: { Authorization: `bearer ${getuser.token}` },
    })
      .then((res) => {
        if (res.data.cart) {
          selectedCategory(category);
          setTicket(res.data.cart.tickets);
          localStorage.setItem(
            "basketsNumber",
            JSON.stringify(res.data.cart.tickets.length)
          );
          setCounter(res.data.cart.cartTime);
          setLoading(false);
      
          setTimeout(() => {
            window.scrollTo({
              behavior: "smooth",
              top: divRef.current.offsetTop,
            });
          }, 200);
        } else {
          setTicket("");
          localStorage.setItem("basketsNumber", JSON.stringify(0));
          setCounter("");
          setLoading(false);
         
        }
      })
      .catch(setLoading(true));
  };

  const getCart = () => {
    const myArray = seatCategory.split(",");
    const myPriceId = myArray[0];
    const myCategoryId = myArray[1];
    const myPrice = myArray[2];
    const myStatus = myArray[3];
    const mySeat = myArray[4];
    const myRelated = myArray[5];

    setSeatId(myPriceId);
    setGroupId(myCategoryId);
    setPrice(myPrice);
    setPriceStatus(myStatus);
    setMySeats([mySeat]);
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (seatCategory !== null) {
      HTTP.post(
        "/addTicketToCart",
        {
          name_on_ticket: ticketNameSurname,
          seat_id: myRelated === "null" ? [mySeat] : [mySeat, myRelated],
          price: myPrice,
          price_id: myPriceId,
          activity_id: activityId,
          is_insurance: insurance == 2 ? 0 : insurance,
          ip_address: ip,
          device_name: device,
          utc: utcLog,
        },
        { headers: { Authorization: `bearer ${getuser.token}` } }
      )
        .then((res) => {
          if (res.data.result) {
            // setCategory("null")
            setSeatCategory("null");
            setMySeats([]);
            setSeatName("");
            setTerm(false);
            setInsurance(0);
            getTicket();
            setCounter(res.data.cart.cartTime);
            setTicketNameSurname(getuser.user.username);
            getSeatChart(category);
            handleClose();
            setLoading(false);
   
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
            setAddStatus(1);
          }
        })
        .catch(setLoading(true));
    }
  };

  const ticketIncrease = () => {
    if (ticketNumber < 20 && ticketNumber < seat.length) {
      const increase = ticketNumber + 1;
      setTicketNumber(increase);
    }
  };

  const ticketDecrease = () => {
    if (ticketNumber > 1) {
      const decrease = ticketNumber - 1;
      setTicketNumber(decrease);
    }
  };

  const onSelect = (selectedList, selectedItem) => {
		setMySeats([...mySeats, selectedItem.id])
	}

	const onRemove = (selectedList, removedItem) => {
		const myRemove = mySeats.filter(function (item) {
			return item !== removedItem.id
		})
		setMySeats(myRemove)
	}
  const addTicketNumber = () => {
    setLoading(true)
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    for (let i = 0; i < ticketNumber; i++) {
      mySeats.push(seat[i].id);
    }
    setMySeats(mySeats);
    if (mySeats.length > 0) {
      HTTP.post(
        "/addTicketToCart",
        {
          name_on_ticket: ticketNameSurname,
          seat_id: mySeats,
          price: price,
          price_id: seatId,
          activity_id: activityId,
          is_insurance: insurance == 2 ? 0 : insurance,
          ip_address: ip,
          device_name: device,
          utc: utcLog,
        },
        { headers: { Authorization: `bearer ${getuser.token}` } }
      )
        .then((res) => {
          if (res.data.result) {
            setSeatCategory("null");
            selectedCategory(category);
            setMySeats([]);
            setSeatName("");
            setTerm(false);
            setInsurance(0);
            getTicket();
            setCounter(res.data.cart.cartTime);
            setTicketNameSurname(getuser.user.username);
            setLoading(false);
      
            setPriceStatus("");
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
            setAddStatus(1);
          }
        })
        .catch(setLoading(true));
    }
  };

  useEffect(
    () => {
      setTimeout(() => {
        if (!planURL){
          if (hall?.groups?.length < 2) {
            setCategory(0);
          }
          if (hall?.groups[0]?.prices?.length == 1) {
            const id = hall?.groups[0]?.prices[0]?.id;
            const group_id = hall?.groups[0]?.prices[0]?.group_id;
            const price = hall?.groups[0]?.prices[0]?.price;
            const status = hall?.groups[0]?.prices[0]?.status;
            getSeat(id + "," + group_id + "," + price + "," + status);
          }

        }
        
      }, 200);
    },
    [hall],
    divRef.current
  );



  const selectedCategory = (category) => {
    if (hall?.groups[category]?.prices?.length == 1) {
      const id = hall?.groups[category]?.prices[0]?.id;
      const group_id = hall?.groups[category]?.prices[0]?.group_id;
      const price = hall?.groups[category]?.prices[0]?.price;
      const status = hall?.groups[category]?.prices[0]?.status;
      getSeat(id + "," + group_id + "," + price + "," + status);
    }
  };

  const tagManagerArgs = {
    gtmId: "GTM-PQCRDTTD",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    window.dataLayer = [];
    if (activityName) {
      window.dataLayer.push({
        page: {
          url: window.location.href,
          title: activityName,
        },
        event: "ticketing_page",
      });
    }
  }, [activityName]);

  const gtmTag = (val) => {
    TagManager.initialize(tagManagerArgs);
    window.dataLayer.push({
      event: val,
    });
  };

  useEffect(() => {
    if (addStatus == 1) {
      window.dataLayer.push({
        ticket: {
          ticket: ticket,
          ticket_number: ticket.length,
        },
        event: "add_to_cart",
      });
    }
  }, [ticket]);

  useEffect(() => {
    if (addStatus == 2) {
      window.dataLayer.push({
        ticket: {
          ticket: ticket,
          ticket_number: ticket.length,
        },
        event: "delete_to_cart",
      });
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket.length > 0 && counter < 0) {
      removeCart();
    }
  }, [ticket, counter]);

  const getSeatChart = (categoryId, status) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0" && status !== "standing") {
      HTTP.get(`/activities/getUnsoldSeatsChart/${categoryId}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          console.log("selam", categoryId,res)
          if (res.data.result) {
            setCategory(categoryId);
            setSeat(res.data.seats);
            setCategoryStatus(status);
            setLoading(false);
            
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    } else {
      setCategoryStatus(status);
      setCategory(categoryId);
      getStanding(categoryId,status);
    }
  };

  useEffect(() => {
    if (!category || !hall?.groups) return;

    let filteredPrices = [];

    // hall.groups bir nesne mi dizi mi kontrol et
    if (Array.isArray(hall.groups)) {
      // Dizi ise find kullan
      const group = hall.groups.find((group) => group.id === category);
      filteredPrices = group?.prices || [];
    } else {
      // Nesne ise doğrudan erişim kullan
      filteredPrices = hall?.groups?.[category]?.prices || [];
    }

    setPrices(filteredPrices);
  }, [category, hall]);

  const getCartPlan = () => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
		const getuser = JSON.parse(localStorage.getItem("auth"));
		HTTP.post("/addTicketToCart", { name_on_ticket: ticketNameSurname, seat_id: mySeats, price: price, price_id: seatId, activity_id: activityId, is_insurance: insurance == 2 ? 0 : insurance, ip_address: ip, device_name: device, utc: utcLog }, { headers: { Authorization: `bearer ${getuser.token}` } })
			.then((res) => {
				if (res.data.result) {
					// setCategory("null")
					setSeatCategory("null")
					setMySeats([])
					setSeatName("")
					setTerm(false)
					setInsurance(0)
					getTicket()
					setCounter(res.data.cart.cartTime)
					setTicketNameSurname(getuser.user.username)
					setLoading(false);
					setTimeout(() => {
						window.scrollTo({
							behavior: "smooth",
							top: divRef.current.offsetTop
						})
					}, 200)
					setAddStatus(1)
				} else {
					setLoading(true);
				}
			})
			.catch(setLoading(true));
	};

  return (
    <div>
      <Helmet>
        <title>Vidipass | {activityName}</title>
        <meta
          name="description"
          content={metaDescription ? metaDescription : description}
        />
        <meta
          name="keywords"
          content={metaKeywords.filter(Boolean).join(", ")}
        />
        <meta name="robots" content="index, follow" />
        <link rel="alternate" href={window.location.href} hreflang="tr" />
        <link rel="canonical" href={window.location.href} hreflang="tr" />
      </Helmet>
      {loading ? <Loader />
      :
      <>
        <Navbar />
        <div className="container mb-5 px-4 mt-5">
          <div
            style={{ marginBottom: isExpanded ? "360px" : "120px" }}
            className="row flex-column flex-lg-row"
          >
            <div className="col-12 card-back p-1 rounded-2">
              <div className="card p-2">
                <div className="row g-1 align-items-center justify-content-center">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    {activityPhoto && (
                      <img
                        src={`${
                          globals.imgUrl
                        }${activityId}/thumb/${activityPhoto}.png?${new Date(
                          imgCreated
                        ).getTime()}`}
                        className="rounded-2 img-fluid activity-photo"
                        alt="activity photo"
                      />
                    )}
                  </div>
                  <div className="col-8 d-flex flex-column align-items-center">
                    <div className="text-center">
                      <h3
                        className="mb-1"
                        style={{ color: "#2E81D2", fontSize: 16 }}
                      >
                        {activityName}
                      </h3>
                      <p className="text-muted mb-1" style={{ fontSize: 12 }}>
                        {_(activityCategoryName)}
                      </p>
                    </div>

                    <div className="d-flex justify-content-center w-100">
                      <div className="text-center me-3">
                        <h4
                          className="mb-0"
                          style={{ fontSize: 11, fontWeight: 500 }}
                        >
                          {_("start_time")}
                        </h4>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          {startDate} - {startTime}
                        </span>
                        <div className="text-muted" style={{ fontSize: 11 }}>
                          {_(startDateDay)}
                        </div>
                      </div>
                      <div className="text-center">
                        <h4
                          className="mb-0"
                          style={{ fontSize: 11, fontWeight: 500 }}
                        >
                          {_("end_time")}
                        </h4>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          {endDate} - {endTime}
                        </span>
                        <div className="text-muted" style={{ fontSize: 11 }}>
                          {_(endDateDay)}
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-1">
                      <h4
                        className="mb-0"
                        style={{ fontSize: 11, fontWeight: 500 }}
                      >
                        {_("place")}
                      </h4>
                      <span className="text-muted" style={{ fontSize: 11 }}>
                        {cityName}, {place}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-1">
                  <small className="text-muted" style={{ fontSize: 10 }}>
                    <span
                      className="bi bi-info-circle"
                      style={{ color: "#933CAC" }}
                    />{" "}
                    {_("activity_detail_info").replace(
                      "%s",
                      `https://vidipass.com/a/${number}`
                    )}
                  </small>
                </div>
              </div>
            </div>
            {serverTime > 0 &&
              activityStatus == 0 &&
              ticket.length < 20 &&
              (planURL ? (
                <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    {seatChart.length > 0 &&
                      (seat.length > 0 ? (
                        categoryStatus === "standing" ? (
                          <>
                           <span
                              onClick={() => window.location.reload()}
                              style={{ color: "#933CAC", cursor: "pointer" }}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <span className="bi bi-arrow-left-square-fill fs-2" />
                             
                            </span>
                            <div className="card-back p-1 rounded-2 mb-2 shadow">
                              <div className="card p-1">
                                <span className="text-muted d-flex mt-auto mb-auto">
                                  <span
                                    style={{ fontSize: 17 }}
                                    class="homeText bi bi-info-circle me-1"
                                  />{" "}
                                  {_("no_seating_arrangement")}
                                </span>
                              </div>
                            </div>

                            <span className="homeText text-start">
                              <i className="bi bi-geo-alt-fill"></i> {hall.name}
                              <hr />
                            </span>

                            {category != "null" && (
                              <>
                                <span className="homeText">
                                  {_("ticket_type")}
                                </span>
                                <div className="col-sm-12 mb-3">
                                  {prices?.map((item, i) => {
                                    const value = `${item.id},${item.group_id},${item.price},${item.status}`;
                                    return (
                                      <div key={i} className="form-check">
                                        <input
                                          type="radio"
                                          className="priceInput form-check-input custom-radio"
                                          id={`price-${i}`}
                                          name="seatCategory"
                                          value={value}
                                          onChange={(e) =>
                                            getSeat(e.target.value)
                                          }
                                          checked={seatCategory === value}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`price-${i}`}
                                        >
                                          {item.name} - {item.price} {priceCode}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                            {seat &&
                              seatCategory !== "null" &&
                              category !== "null" && (
                                <>
                                  {seat.length > 0 ? (
                                    <>
                                      <span className="homeText">
                                        {" "}
                                        {_("number_of_tickect")}
                                      </span>
                                      <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
                                        {ticketNumber > 1 ? (
                                          <span
                                            onClick={() => ticketDecrease()}
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              color: "#933CAC",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              display: "flex",
                                              fontSize: 20,
                                            }}
                                            className=" bi bi-dash-circle rounded p-2"
                                          ></span>
                                        ) : (
                                          <span
                                            onClick={() => ticketDecrease()}
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              color: "#CDCDCD",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              display: "flex",
                                              fontSize: 20,
                                            }}
                                            className=" bi bi-dash-circle rounded p-2"
                                          ></span>
                                        )}
                                        <input
                                          value={ticketNumber}
                                          disabled
                                          style={{
                                            width: 50,
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          type="text"
                                          className="form-control me-1 ms-1 text-center"
                                        />
                                        {ticketNumber < 20 &&
                                        ticketNumber < seat.length ? (
                                          <span
                                            onClick={() => ticketIncrease()}
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              color: "#933CAC",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              display: "flex",
                                              fontSize: 20,
                                            }}
                                            className=" bi bi-plus-circle rounded p-2"
                                          ></span>
                                        ) : (
                                          <span
                                            onClick={() => ticketIncrease()}
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              color: "#CDCDCD",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              display: "flex",
                                              fontSize: 20,
                                            }}
                                            className=" bi bi-plus-circle rounded p-2"
                                          ></span>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <span>{_("no_seats_search_criteria")}</span>
                                  )}
                                </>
                              )}

                            {seat &&
                              seatCategory !== "null" &&
                              category !== "null" &&
                              (mySeats.length !== 0 || ticketNumber > 0) && (
                                <>
                                  <span className="homeText">
                                    {" "}
                                    {_("name_on_ticket")}
                                  </span>
                                  <span
                                    style={{ fontSize: 14 }}
                                    className="text-muted"
                                  >
                                    {_("add_name_to_ticket")}{" "}
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      className="homeText"
                                    >
                                      |
                                    </span>{" "}
                                    0 TRY
                                  </span>
                                  <span
                                    style={{ fontSize: 14 }}
                                    className="text-muted"
                                  >
                                    {_("name_on_ticket_info")}
                                  </span>
                                  <div className="col-sm-12">
                                    <div className="form-group">
                                      <input
                                        required
                                        type="text"
                                        value={ticketNameSurname}
                                        onChange={(e) =>
                                          setTicketNameSurname(e.target.value)
                                        }
                                        placeholder={_("name_on_ticket")}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  {moment(startDateTime).diff(
                                    moment(),
                                    "seconds"
                                  ) > 86400 && (
                                    <>
                                      <span className="homeText mt-3">
                                        {" "}
                                        {_("ticket_insurance")}
                                      </span>
                                      <div className="form-check">
                                        <label
                                          className="form-check-label"
                                          htmlFor="exampleCheck1"
                                        >
                                          <input
                                            type="checkbox"
                                            className="priceInput form-check-input text-muted"
                                            id="exampleCheck1"
                                            checked={term}
                                            onChange={onChangeTerm}
                                          />
                                          <div className="row">
                                            <span
                                              style={{ fontSize: 14 }}
                                              className="text-muted"
                                            >
                                              {_("to_ticket_insurance")}{" "}
                                              <span
                                                style={{ fontWeight: "bold" }}
                                                className="homeText"
                                              >
                                                |
                                              </span>{" "}
                                              10 TRY
                                            </span>
                                            <span
                                              style={{ fontSize: 14 }}
                                              className="text-muted"
                                            >
                                              {" "}
                                              {_("insurance_info")}
                                            </span>
                                          </div>
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}

                            {seat &&
                              (mySeats.length !== 0 || ticketNumber > 0) &&
                              seatCategory !== "null" &&
                              category !== "null" &&
                              mySeats &&
                              (activatedMail ? (
                                <>
                                  <div className="mt-3">
                                    <span className="homeText">
                                      {" "}
                                      {_("e_mail")}
                                    </span>
                                    <div className="row">
                                      <span
                                        style={{ fontSize: 14 }}
                                        className="text-muted"
                                      >
                                        {_("want_to_email")}{" "}
                                        <span
                                          style={{ fontWeight: "bold" }}
                                          className="homeText"
                                        >
                                          |
                                        </span>{" "}
                                        0 TRY
                                      </span>
                                      <span
                                        style={{ fontSize: 14 }}
                                        className="text-muted"
                                      >
                                        {_("ticket_email")}
                                      </span>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="input-group">
                                        <input
                                          disabled={true}
                                          required
                                          type="text"
                                          value={activatedMail}
                                          placeholder={_("e_mail")}
                                          className="form-control text-muted bg-white"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row justify-content-center my-3 px-3">
                                    <a
                                      onClick={() => addTicketNumber()}
                                      className="text-decoration-none btn btn-primary cardButton rounded-2 col-6"
                                    >
                                      {_("add_to_cart")}
                                    </a>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {
                                    <div className="row justify-content-center my-3 px-3">
                                      <a
                                        onClick={() => addTicketNumber}
                                        className="text-decoration-none btn btn-primary cardButton rounded-2 col-6"
                                      >
                                        {_("add_to_cart")}
                                      </a>
                                    </div>
                                  }
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                           <span
                              onClick={() => window.location.reload()}
                              style={{ color: "#933CAC", cursor: "pointer" }}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <span className="bi bi-arrow-left-square-fill fs-2" />
                             
                            </span>
                            <div className="d-flex flex-row gap-2 align-items-start my-1 flex-wrap">
                              {/* İlk Satır: Boş Küçük Modern Çerçeve */}
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box normal"></div>
                                <span className="modern-text">
                                  {_("standart_seat")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box disable"></div>
                                <span className="modern-text">
                                  {_("disable_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box rounded-left"></div>
                                <div className="modern-box rounded-right"></div>
                                <span className="modern-text">
                                  {_("double_seat")}
                                </span>
                              </div>

                              {/* İkinci Satır: Mor Küçük Modern Çerçeve */}
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box selectable"></div>
                                <span className="modern-text">
                                  {_("selectable_seat")}
                                </span>
                              </div>

                             
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box sold"></div>
                                <span className="modern-text">
                                  {_("full_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box myBasket"></div>
                                <span className="modern-text">
                                  {_("my_basket_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box otherBasket"></div>
                                <span className="modern-text">
                                  {_("other_basket_seat")}
                                </span>
                              </div>

                              {/* Üçüncü Satır: Yuvarlatılmış Çerçeveler */}
                            </div>
                            <div
                              id="seat-map"
                              className="seat-map border border-secondary-subtle p-2 rounded-2"
                            >
                              {seat.map((row, rowIndex) => (
                                <div key={rowIndex} className="seat-row">
                                  {row.row_label ? (
                                    <div className="seat-row-label">
                                      <span
                                        style={{
                                          border: "3px solid #a5a7a9",
                                          boxShadow:
                                            "0 0 10px 0 rgba(100,100,100,1)",
                                        }}
                                        className="seat-label rounded"
                                      >
                                        {row.row_label}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="seat-row-label" />
                                  )}

                                  {row?.seat.map((seat, colIndex) => {
                                    const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                    const isEmpty = seat.status === "empty";
                                    const isClose = seat.is_active === 0;
                                    const isBasket = seat.basket === "yourBasket"

                                    const isClickable =
                                      seat.is_solded === 0 &&
                                      seat.basket !== "anotherBasket";

                                    const isSeat = seat.status === "seat";
                                    const isStanding =
                                      seat.status === "standing";
                                    return (
                                      <div
                                        style={{
                                          backgroundColor:
                                            isStage && seat.color,
                                        }}
                                      >
                                        <Popover.Root key={colIndex} onOpenChange={setPopoverOpened}>
                                          <Popover.Trigger asChild>
                                            <div
                                              className={`${isClickable ? "seat":"inactiveSeat"} ${
                                                isStage ? "stage-seat" : ""
                                              } ${
                                                seat.position_type === "left" &&
                                                "leftLoveSeat"
                                              } 
                                            ${
                                              seat.position_type === "right" &&
                                              "rightLoveSeat"
                                            } 
                                            ${
                                              seat.type === "disable" &&
                                              "disableSeat"
                                            }
                                            ${
                                              seat.type === "normal" &&
                                              "normalSeat"
                                            }
                                            ${
                                              isStanding &&
                                              isClose &&
                                              "closeSeat"
                                            }
                                            ${
                                              isStanding &&
                                              !isClose &&
                                              "normalSeat"
                                            }
                                            ${
                                              seat.is_solded === 0 &&
                                              "selectableSeatBack"
                                            }
                                            ${
                                              (seat.is_solded === 1 || (seat.is_active === 0 && isSeat)) &&
                                              "soldSeatBack"
                                            }
                                            
                                            ${
                                              seat.basket === "yourBasket" &&
                                              "myBasketSeatBack"
                                            }
                                            ${
                                              seat.basket === "anotherBasket" &&
                                              "otherBasketSeatBack"
                                            }
                                           
                                            `}
                                              style={{
                                                color: "#FFFFFF",
                                                letterSpacing: "1px",
                                                boxShadow: !isEmpty &&
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                          
                                              }}
                                              onClick={() =>
                                                (isClickable && !isBasket)&&
                                                setMySeats([]) + 
                                                setIsExpanded(false) +
                                                setSeatCategory("null")+
                                                setSeatName("")+
                                                setTerm(false)+
                                                setInsurance(0)+
                                                saveSeat(seat.id,seat.related_id && seat.related_id)
                                              } // Dummy onClick, asıl işlevi Popover kullanır
                                            >
                                              {seat.name && (
                                                <span
                                                  className={`seat-label d-flex justify-content-center`}
                                                >
                                                  {seat.name}
                                                </span>
                                              )}
                                            </div>
                                          </Popover.Trigger>

                                          {isClickable && (
                                            <Popover.Portal>
                                              <Popover.Content
                                                className="PopoverContent border border-primary-subtle"
                                                sideOffset={-20}
                                                style={{
                                                  position: "relative", // Popover.Content'in konumunu relative yapıyoruz ki close butonunu yerleştirebilelim
                                                }}
                                              >
                                                {
                                                  <div ref={popoverContentRef} className="scrollable-container mt-3">
                                                    <div
                                                    className="mx-1"
                                                   
                                                  >
                                                    
                                                     <span
                                                        className={`d-flex align-items-center justify-content-start homeText gap-1`}
                                                      >
                                                        <span className={seat.type==="disable"?"fas fa-wheelchair":seat.type==="love"?"fas fa-couch":"fas fa-chair"}/>
                                                        <span>{seat.name}</span>
                                                    </span>
                                                    {
                                                      isBasket ? 
                                                      <span className="homeText text-muted mt-1">{_("this_seat_in_your_basket")}</span>
                                                   :
                                                    <>
                                                    
                                                    <div className="card p-1"> 
                                                    <span className="homeText">
                                                      {_("ticket_type")}
                                                    </span>
                                                    <div className="col-sm-12 mb-3">
                                                      {seat?.group.prices?.map(
                                                        (item, i) => {
                                                          const value = `${
                                                            item.id
                                                          },${item.group_id},${
                                                            item.price
                                                          },${item.status},${
                                                            seat.id
                                                          },${
                                                            seat.related_id &&
                                                            seat.related_id
                                                          }`;
                                                          return (
                                                            <div
                                                              key={i}
                                                              className="form-check"
                                                            >
                                                              <input
                                                                type="radio"
                                                                className="priceInput form-check-input custom-radio"
                                                                id={`price-${i}`}
                                                                name="seatCategory"
                                                                value={value}
                                                                onChange={(e) =>
                                                                  setSeatCategory(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                checked={
                                                                  seatCategory===
                                                                  value
                                                                }
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor={`price-${i}`}
                                                              >
                                                                {item.name} -{" "}
                                                                {item.price}{" "}
                                                                {priceCode}
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>

                                                    </div>
                                                    

                                                    {seat &&
                                                      seatCategory !== "null" &&
                                                      category !== "null" && (
                                                        <>
                                                        <div className="card p-1 mt-1">

                                                        
                                                          <span className="homeText">
                                                            {" "}
                                                            {_(
                                                              "name_on_ticket"
                                                            )}
                                                          </span>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                              }}
                                                              className="text-muted"
                                                            >
                                                              {_(
                                                                "add_name_to_ticket"
                                                              )}{" "}
                                                              <span
                                                                style={{
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                className="homeText"
                                                              >
                                                                |
                                                              </span>{" "}
                                                              0 TRY
                                                            </span>
                                                          </div>

                                                          <span
                                                            style={{
                                                              fontSize: 14,
                                                            }}
                                                            className="text-muted"
                                                          >
                                                            {_(
                                                              "name_on_ticket_info"
                                                            )}
                                                          </span>
                                                          <div className="col-sm-12">
                                                            <div className="form-group">
                                                              <input
                                                                required
                                                                type="text"
                                                                value={
                                                                  ticketNameSurname
                                                                }
                                                                onChange={(e) =>
                                                                  setTicketNameSurname(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                placeholder={_(
                                                                  "name_on_ticket"
                                                                )}
                                                                className="form-control"
                                                              />
                                                            </div>
                                                          </div>
                                                          </div>
                                                          {moment(
                                                            startDateTime
                                                          ).diff(
                                                            moment(),
                                                            "seconds"
                                                          ) > 86400 && (
                                                            <div className="card p-1 mt-1">
                                                              <span className="homeText">
                                                                {" "}
                                                                {_(
                                                                  "ticket_insurance"
                                                                )}
                                                              </span>
                                                              <div className="form-check">
                                                                <label
                                                                  className="form-check-label"
                                                                  htmlFor="exampleCheck1"
                                                                >
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input text-muted"
                                                                    id="exampleCheck1"
                                                                    checked={
                                                                      term
                                                                    }
                                                                    onChange={
                                                                      onChangeTerm
                                                                    }
                                                                  />
                                                                  <div className="row">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 14,
                                                                      }}
                                                                      className="text-muted"
                                                                    >
                                                                      {_(
                                                                        "to_ticket_insurance"
                                                                      )}{" "}
                                                                      <span
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        className="homeText"
                                                                      >
                                                                        |
                                                                      </span>{" "}
                                                                      10 TRY
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 14,
                                                                      }}
                                                                      className="text-muted"
                                                                    >
                                                                      {" "}
                                                                      {_(
                                                                        "insurance_info"
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </div>
                                                          )}

                                                          {activatedMail ? (
                                                            <>
                                                              <div className="card p-1 mt-1">
                                                                <span className="homeText">
                                                                  {" "}
                                                                  {_("e_mail")}
                                                                </span>
                                                                <div className="row">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 14,
                                                                    }}
                                                                    className="text-muted"
                                                                  >
                                                                    {_(
                                                                      "want_to_email"
                                                                    )}{" "}
                                                                    <span
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                      className="homeText"
                                                                    >
                                                                      |
                                                                    </span>{" "}
                                                                    0 TRY
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 14,
                                                                    }}
                                                                    className="text-muted"
                                                                  >
                                                                    {_(
                                                                      "ticket_email"
                                                                    )}
                                                                  </span>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                  <div className="input-group">
                                                                    <input
                                                                      disabled={
                                                                        true
                                                                      }
                                                                      required
                                                                      type="text"
                                                                      value={
                                                                        activatedMail
                                                                      }
                                                                      placeholder={_(
                                                                        "e_mail"
                                                                      )}
                                                                      className="form-control text-muted bg-white"
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="row justify-content-center my-3 mx-1">
                                                                <a
                                                                  onClick={
                                                                    getCart
                                                                  }
                                                                  className="text-decoration-none btn btn-primary cardButton rounded-2 col-9"
                                                                >
                                                                  {_(
                                                                    "add_to_cart"
                                                                  )}
                                                                </a>
                                                              </div>
                                                             
                                                            </>
                                                          ) : (
                                                            <>
                                                              {
                                                                <div className="row justify-content-center my-3 mx-1">
                                                                  <a
                                                                    onClick={
                                                                      getCart
                                                                    }
                                                                    className="text-decoration-none btn btn-primary cardButton rounded-2 col-9"
                                                                  >
                                                                    {_(
                                                                      "add_to_cart"
                                                                    )}
                                                                  </a>
                                                                </div>
                                                              }
                                                            </>
                                                          )}
                                                         
                                                        </>
                                                      )}
                                                      </>
                                                       }
                                                  </div>
                                                 
                                                  </div>
                                                }

                                                <Popover.Close
                                                  ref={closeRef}
                                                  className="bi bi-x-circle-fill PopoverClose"
                                                />
                                                <Popover.Arrow className="PopoverArrow" />
                                                <Modal
                                                  show={cancelInsuranceModal}
                                                  onHide={() =>
                                                    setCancelInsuranceModal(
                                                      false
                                                    )
                                                  }
                                                >
                                                  <Modal.Header
                                                    closeButton
                                                    style={{
                                                      background: "#fff",
                                                    }}
                                                  >
                                                    <Modal.Title
                                                      style={{ fontSize: 16 }}
                                                    >
                                                      <span className="homeText">
                                                        {_("cancel_insurance")}
                                                      </span>
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <p
                                                        style={{ fontSize: 14 }}
                                                        className="text_muted"
                                                      >
                                                        {_(
                                                          "cancel_insurance_text"
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <p className="homeText">
                                                        {_(
                                                          "cancel_insurance_sure"
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <div className="row">
                                                        <div
                                                          className="col text-end"
                                                          onClick={(e) =>
                                                            setTerm(!term) +
                                                            setInsurance(0) +
                                                            setCancelInsuranceModal(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            <i className="bi bi-check-circle-fill"></i>
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="col text-end"
                                                          onClick={() =>
                                                            setTerm(term) +
                                                            setInsurance(1) +
                                                            setCancelInsuranceModal(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            <i className="bi bi-x-circle-fill"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Modal.Body>
                                                </Modal>
                                              </Popover.Content>
                                            </Popover.Portal>
                                          )}
                                        </Popover.Root>
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                            </div>

                           
                          </>
                        )
                      ) : (
                        <>
                        <span className="d-flex justify-content-center homeText my-2">
                          
                          {_("select_category")}
                        </span>
                        <div id="seat-map" className="seat-map-ticket">
                          {seatChart.map((row, rowIndex) => (
                            <div key={rowIndex} className="seat-row">
                              {row.seat.map((seat, colIndex) => {
                                const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                const isSeat = seat.status === "seat";
                                const isStanding = seat.status === "standing";
                                const isClickable =
                                  seat.status === "seat" ||
                                  seat.status === "standing";

                                // Center stage hesaplama
                                const groupCenter =
                                  groupCenters[seat.group?.name || seat.status];
                                const isGroupCenter =
                                  groupCenter &&
                                  groupCenter.center &&
                                  groupCenter.center.rowIndex === rowIndex &&
                                  groupCenter.center.colIndex === colIndex;
                                const positionType =
                                  groupCenter?.positionType || 4;
                                return (
                                  <div style={{ backgroundColor: seat.color }}>
                                    {isClickable ? (
                                      <div
                                        className={`chartSeat`}
                                        style={{
                                          backgroundColor: seat.color,
                                          border: "none",
                                          cursor: "default",
                                        }}
                                        onClick={() =>
                                          getSeatChart(
                                            seat.group_id,
                                            seat.status
                                          )
                                        }
                                      >
                                        {isGroupCenter &&
                                          (isSeat || isStanding) && (
                                            <div
                                              className="rounded"
                                              style={{
                                                position: "absolute",
                                                top:
                                                  positionType === 1
                                                    ? "50%"
                                                    : positionType === 2
                                                    ? "50%"
                                                    : positionType === 3
                                                    ? "100%"
                                                    : "100%",
                                                left:
                                                  positionType === 1
                                                    ? "50%"
                                                    : positionType === 2
                                                    ? "100%"
                                                    : positionType === 3
                                                    ? "50%"
                                                    : "100%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                                zIndex: 3,

                                                color: "#FFFFFF",
                                              }}
                                            >
                                              <span style={{ fontSize: 12 }}>
                                                {seat.group?.name}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      <div
                                        className={`chartSeat`}
                                        style={{
                                          cursor: "default",
                                          backgroundColor: seat.color,
                                        }}
                                      >
                                        {isStage && isGroupCenter && (
                                          <div
                                            className="rounded"
                                            style={{
                                              position: "absolute",
                                              top:
                                                positionType === 1
                                                  ? "50%"
                                                  : positionType === 2
                                                  ? "50%"
                                                  : positionType === 3
                                                  ? "100%"
                                                  : "100%",
                                              left:
                                                positionType === 1
                                                  ? "50%"
                                                  : positionType === 2
                                                  ? "100%"
                                                  : positionType === 3
                                                  ? "50%"
                                                  : "100%",
                                              transform:
                                                "translate(-50%, -50%)",
                                              zIndex: 3,

                                              color: "#FFFFFF",
                                            }}
                                          >
                                            <span style={{ fontSize: 12 }}>
                                              {_("stage")}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </div>
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    <div className="card-back p-1 rounded-2 mb-2 shadow">
                      <div className="card p-1">
                      {
                        planImage ?
                        <div className="text-start" onClick={() => setShowSaloonModal(true) + gtmTag('seating_arrangement')}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													{_("seating_arrangement")}
												</a>
											</div>:
                       <span className="text-muted d-flex mt-auto mb-auto">
                       <span
                         style={{ fontSize: 17 }}
                         class="homeText bi bi-info-circle me-1"
                       />{" "}
                       {_("no_seating_arrangement")}
                     </span>

                      }
                       

                      </div>
                    </div>

                    <span className="homeText text-start">
                      <i className="bi bi-geo-alt-fill"></i> {hall.name}
                      <hr />
                    </span>

                    <span className="homeText">{_("category")}</span>
                    <div className="col-sm-12 mb-3">
                      {hall?.groups?.map((item, i) => {
                        return (
                          <div key={i} className="form-check">
                            <input
                              type="radio"
                              className="priceInput form-check-input custom-radio"
                              id={`category-${i}`}
                              name="category"
                              value={i}
                              onChange={(e) => {
                                setCategory(e.target.value);
                                setMySeats([]);
                                setSeatName("");
                                setSeatCategory("null");
                                selectedCategory(e.target.value);
                                setTimeout(() => {
                                  window.scrollTo({
                                    behavior: "smooth",
                                    top: divRef.current.offsetTop,
                                  });
                                }, 200);
                              }}
                              checked={category == i}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`category-${i}`}
                            >
                              {item.name} 
                              { item.total_tickets===item.solded_tickets&&
                              <small className="text-muted">({_("sold_out")})</small>
                             

                              }
                             
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {category != "null" && (
                      <>
                        <span className="homeText">{_("ticket_type")}</span>
                        <div className="col-sm-12 mb-3">
                          {hall?.groups[category]?.prices?.map((item, i) => {
                            const value = `${item.id},${item.group_id},${item.price},${item.status}`;
                            return (
                              <div key={i} className="form-check">
                                <input
                                  type="radio"
                                  className="priceInput form-check-input custom-radio"
                                  id={`price-${i}`}
                                  name="seatCategory"
                                  value={value}
                                  onChange={(e) => getSeat(e.target.value)}
                                  checked={seatCategory === value}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`price-${i}`}
                                >
                                  {item.name} - {item.price} {priceCode}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                   {seat && seatCategory !== "null" && category !== "null" && (
										<>
											{
												priceStatus == 1 ?
													<>{
														seat.length>0 ? 
														<>
															<span className="homeText"> {_('number_of_tickect')}</span>
														<div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
															{
																ticketNumber > 1 ?
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
																	:
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
															}
															<input value={ticketNumber} disabled style={{ width: 50, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
															{
																(ticketNumber < 20 && ticketNumber < seat.length) ?
																	<span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
																	:
																	<span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
															}
														</div>
														</>:
														<span>{_('no_seats_search_criteria')}</span>
													}
													
													</>
													:
													<>
														<span className="homeText"> {_('armchair')}</span>
														<div className="col-sm-12 mb-3">
															<Multiselect
																className="bg-white"
																options={seat}
																displayValue="name"
																selectionLimit={20}
																avoidHighlightFirstOption
																emptyRecordMsg={_('no_seats_search_criteria')}
																placeholder={_('please_select_seat')}
																selectedValues={seat.selectedValue}
																onSelect={onSelect}
																onRemove={onRemove}
																style={{
																	searchBox: { // To change search box element look
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	inputField: { // To change input field position or margin
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	chips: { // To change css chips(Selected options)
																		backgroundColor: '#933CAC'
																	},
																	optionContainer: { // To change css for option container 
																		maxHeight: '150px', // Açılan kutunun maksimum yüksekliği
																		overflowY: 'auto', // Kaydırma çubuğu ekler
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	}
																}}
															/>
														</div>
													</>
											}
										</>
									)}

                    {seat &&
                      seatCategory !== "null" &&
                      category !== "null" &&
                      (mySeats.length !== 0 || ticketNumber > 0) && (
                        <>
                          <span className="homeText">
                            {" "}
                            {_("name_on_ticket")}
                          </span>
                          <span style={{ fontSize: 14 }} className="text-muted">
                            {_("add_name_to_ticket")}{" "}
                            <span
                              style={{ fontWeight: "bold" }}
                              className="homeText"
                            >
                              |
                            </span>{" "}
                            0 TRY
                          </span>
                          <span style={{ fontSize: 14 }} className="text-muted">
                            {_("name_on_ticket_info")}
                          </span>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <input
                                required
                                type="text"
                                value={ticketNameSurname}
                                onChange={(e) =>
                                  setTicketNameSurname(e.target.value)
                                }
                                placeholder={_("name_on_ticket")}
                                className="form-control"
                              />
                            </div>
                          </div>
                          {moment(startDateTime).diff(moment(), "seconds") >
                            86400 && (
                            <>
                              <span className="homeText mt-3">
                                {" "}
                                {_("ticket_insurance")}
                              </span>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  <input
                                    type="checkbox"
                                    className="priceInput form-check-input text-muted"
                                    id="exampleCheck1"
                                    checked={term}
                                    onChange={onChangeTerm}
                                  />
                                  <div className="row">
                                    <span
                                      style={{ fontSize: 14 }}
                                      className="text-muted"
                                    >
                                      {_("to_ticket_insurance")}{" "}
                                      <span
                                        style={{ fontWeight: "bold" }}
                                        className="homeText"
                                      >
                                        |
                                      </span>{" "}
                                      10 TRY
                                    </span>
                                    <span
                                      style={{ fontSize: 14 }}
                                      className="text-muted"
                                    >
                                      {" "}
                                      {_("insurance_info")}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </>
                          )}
                        </>
                      )}

                    {seat &&
                      (mySeats.length !== 0 || ticketNumber > 0) &&
                      seatCategory !== "null" &&
                      category !== "null" &&
                      mySeats &&
                      (activatedMail ? (
                        <>
                          <div className="mt-3">
                            <span className="homeText"> {_("e_mail")}</span>
                            <div className="row">
                              <span
                                style={{ fontSize: 14 }}
                                className="text-muted"
                              >
                                {_("want_to_email")}{" "}
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="homeText"
                                >
                                  |
                                </span>{" "}
                                0 TRY
                              </span>
                              <span
                                style={{ fontSize: 14 }}
                                className="text-muted"
                              >
                                {_("ticket_email")}
                              </span>
                            </div>
                            <div className="col-sm-12">
                              <div className="input-group">
                                <input
                                  disabled={true}
                                  required
                                  type="text"
                                  value={activatedMail}
                                  placeholder={_("e_mail")}
                                  className="form-control text-muted bg-white"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center my-3 px-3">
                            <a
                              onClick={() => priceStatus != 1 ? getCartPlan() : addTicketNumber()}
                              className="text-decoration-none btn btn-primary cardButton rounded-2 col-6"
                            >
                              {_("add_to_cart")}
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          {
                            <div className="row justify-content-center my-3 px-3">
                              <a
                                onClick={() => priceStatus != 1 ? getCartPlan() : addTicketNumber()}
                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-6"
                              >
                                {_("add_to_cart")}
                              </a>
                            </div>
                          }
                        </>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div onClick={() => gtmTag("whatsapp_icon")}>
          <WhatsAppWidget
            phoneNo="905010700526"
            position="right"
            widgetWidth="300px"
            widgetWidthMobile="260px"
            // autoOpen={true}
            // autoOpenTimer={2000}
            messageBox={true}
            messageBoxTxt=""
            iconSize="40"
            iconColor="white"
            iconBgColor="green"
            headerIcon={images.vidipass_logo}
            headerIconColor="black"
            headerTxtColor="white"
            headerBgColor="#933CAC"
            headerTitle="Vidipass"
            headerCaption={_("online")}
            bodyBgColor="#bbb"
            chatPersonName={_("vidipass_assistant")}
            chatMessage={
              <>
                {_("hi")} 👋 <br />
                {_("how_can_help")}
              </>
            }
            footerBgColor="#999"
            placeholder={_("write_to_assistant")}
            btnBgColor="#933CAC"
            btnTxt={_("send")}
            btnTxtColor="white"
          />
        </div>
        <span ref={divRef} />
        {ticket.length > 0 && (
          <div
            className={`popover-container rounded ${
              isExpanded ? "expanded" : "collapsed"
            }`}
          >
            <div className="popover-header d-flex justify-content-between align-items-center position-relative px-2 mt-2">
              {/* Sol Taraf */}
              <span style={{ backgroundColor: '#933CAC', color: '#FFFFFF' }} className="bi bi-basket navLink wrapper me-1 rounded px-2 py-1" ></span>
              <h6 className="homeText mb-0">
                {_("number_of_tickets")}: {ticket.length}
              </h6>
              

              {/* Orta Taraf */}
              {counter > 0 && (
                <div className="homeText position-absolute start-50 translate-middle-x">
                  <CountdownCircleTimer
                    isPlaying
                    duration={counter}
                    colors={["#2E81D2", "#933CAC", "#D31095", "#EC008C"]}
                    size={40}
                    colorsTime={[700, 500, 300, 0]}
                    strokeWidth={2}
                    trailColor="#EC008C"
                    trailStrokeWidth={2}
                    strokeLinecap="square"
                    onComplete={() => removeCart()}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </div>
              )}

              {/* Sağ Taraf */}
              <button
                className="toggle-button btn btn-sm d-flex align-items-center ms-auto rounded-5"
                onClick={togglePopover}
                style={{
                  backgroundColor: "#933CAC",
                  color: "#FFF",
                }}
              >
                {isExpanded ? (
                  <strong class="bi bi-arrow-down" />
                ) : (
                  <span class="bi bi-arrow-up" />
                )}
              </button>
            </div>

            <div className="row justify-content-center my-3 px-3">
              <a
                onClick={() =>
                  (window.dataLayer =
                    [] +
                    window.dataLayer.push({
                      ticket: {
                        ticket: ticket,
                        ticket_number: ticket.length,
                      },
                      event: "cart_details",
                    }))
                }
                className="text-decoration-none btn btn-primary cardButton rounded-2 col-5"
                href={globals.url + "i/payment/"}
              >
                {_("pass_to_pay")}
              </a>
            </div>
            <div className="popover-content">
              {ticket.map((val, i) => {
                return (
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3"
                    key={i}
                  >
                    <div className="card-back p-1 rounded my-1 mx-1">
                      <div className="card">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="my-0 homeText text-truncate ms-1">
                            {val?.activity?.title}
                          </h6>
                          <button
                            onClick={(e) => {
                              frontRemoveTicket(val.id);
                              gtmTag("delete_ticket");
                            }}
                            className="btn btn-sm d-flex align-items-center ms-auto"
                            style={{
                              backgroundColor: "#933CAC",
                              color: "#FFF",
                            }}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </div>

                        <div className="d-flex flex-wrap align-items-center gap-2 ms-1">
                          {hall?.groups?.map((item, i) => {
                            return (
                              item.id == val.current_price.group_id && (
                                <>
                                  <small className="text-truncate homeText">
                                    {_("info")} :
                                  </small>
                                  <small className="text-muted text-truncate">
                                    {item.name}
                                  </small>
                                  <small className="text-muted text-truncate">
                                    {_("/")} {val.current_price.name}
                                  </small>
                                  {val.is_insurance ? (
                                    <small className="text-muted text-truncate">
                                      {_("/")}{" "}
                                      {val.current_price.price +
                                        val.insurance_fee}{" "}
                                      {priceCode}
                                    </small>
                                  ) : (
                                    <small className="text-muted text-truncate">
                                      {_("/")} {val.current_price.price}{" "}
                                      {priceCode}
                                    </small>
                                  )}
                                  {val.current_price.status == 1 ? (
                                    <small className="text-muted text-truncate">
                                      {_("/")} {_("numberless")}
                                    </small>
                                  ) : (
                                    <small className="text-muted text-truncate">
                                      {_("/")} {val.seat.name}
                                    </small>
                                  )}
                                </>
                              )
                            );
                          })}
                        </div>
                        <div className="d-flex flex-wrap align-items-center gap-2 ms-1">
                          <small className="text-truncate homeText">
                            {_("time")} :
                          </small>
                          <small className="text-muted text-truncate">
                            {moment(val?.activity?.start_time).format(
                              "DD.MM.YYYY"
                            )}
                          </small>
                          <small className="text-muted text-truncate">
                            {_(
                              moment(val?.activity?.start_time)
                                .format("dddd")
                                .toLocaleLowerCase()
                            )}
                          </small>
                          <small className="text-muted text-truncate">
                            {moment(val?.activity?.start_time).format("HH:mm")}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <Modal
          show={deleteTicketModal}
          onHide={() => setDeleteTicketModal(false)}
        >
          <Modal.Header closeButton style={{ background: "#fff" }}>
            <Modal.Title style={{ fontSize: 16 }}>
              <span className="homeText">{_("delete_ticket")}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h6 className="homeText">{_("sure")}</h6>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="row">
                <div
                  className="col text-end"
                  onClick={(e) =>
                    removeTicket(deleteTicketId) + setDeleteTicketModal(false)
                  }
                >
                  <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                    <i className="bi bi-check-circle-fill"></i>
                  </a>
                </div>
                <div
                  className="col text-end"
                  onClick={() => setDeleteTicketModal(false)}
                >
                  <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                    <i className="bi bi-x-circle-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
                                                  show={cancelInsuranceModal && categoryStatus!=="seat"}
                                                  onHide={() =>
                                                    setCancelInsuranceModal(
                                                      false
                                                    )
                                                  }
                                                >
                                                  <Modal.Header
                                                    closeButton
                                                    style={{
                                                      background: "#fff",
                                                    }}
                                                  >
                                                    <Modal.Title
                                                      style={{ fontSize: 16 }}
                                                    >
                                                      <span className="homeText">
                                                        {_("cancel_insurance")}
                                                      </span>
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <p
                                                        style={{ fontSize: 14 }}
                                                        className="text_muted"
                                                      >
                                                        {_(
                                                          "cancel_insurance_text"
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <p className="homeText">
                                                        {_(
                                                          "cancel_insurance_sure"
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <div className="row">
                                                        <div
                                                          className="col text-end"
                                                          onClick={(e) =>
                                                            setTerm(!term) +
                                                            setInsurance(0) +
                                                            setCancelInsuranceModal(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            <i className="bi bi-check-circle-fill"></i>
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="col text-end"
                                                          onClick={() =>
                                                            setTerm(term) +
                                                            setInsurance(1) +
                                                            setCancelInsuranceModal(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            <i className="bi bi-x-circle-fill"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Modal.Body>
                                                </Modal>
                                                <Modal show={showSaloonModal} onHide={() => setShowSaloonModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("seating_arrangement")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="col-12 d-flex justify-content-center align-items-center">
										<Zoom>
											<div className="col-12 d-flex justify-content-center">
												<img src={globals.imgUrl + activityId + "/hall/" + "hall" + ".png" + '?' + new Date(imgCreated).getTime()} className="w-50" />
											</div>
										</Zoom>
									</div>
								</Modal.Body>
							</Modal>
        

        <Footer />
      </>
      }
    </div>
  );
};

export default Ticketing;
