import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import { _ } from "../config/languages/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import images from "../images/images";
import globals from "../config/globals/globals";
import axios from "axios";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import moment from "moment";
import { getCities } from "../config/cities/cities";
import Loader from "../components/Loader";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { tr, de, enUS, af, ar, az, bg, bs, cy, el, es, fi, fr, he, hi, hr, hu, it, ja, kk, ko, lb, mk, ms, nl, nn, pt, ro, ru, sq, sr, sv, uk, zhTW } from "date-fns/locale";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Home = () => {
    const timestamp = new Date();
    const [activity, setActivity] = useState([]);
    const [categories, setCategories] = useState([]);
    const cities = getCities();
    const [loading, setLoading] = useState(false);
    const cookies = new Cookies();
    const [lang] = useState(cookies.get("language"));
    const [openDate, setOpenDate] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(true);
    const [socialModal, setSocialModal] = useState(false);
    const [imagesSlide, setImagesSlide] = useState([]);
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
            key: "selection"
        }
    ]);
    const [initialDate, setInitialDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
            key: "selection"
        }
    ]);
    const [city, setCity] = useState(0);
    const [search, setSearch] = useState("");
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(() => {
        const start = moment(date[0].startDate).format("YYYY-MM-DD");
        const end = moment(date[0].endDate).format("YYYY-MM-DD");
        setLoading(true);
        HTTP.get(`activities/getAllLogout?term&start=${start}&end=${end}&city&cats`)
            .then((res) => {

                setLoading(false);
                if (res.data.result) {
                    setActivity(res.data.activities.data)
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);
    useEffect(() => {
        setLoading(true);
        HTTP.get("/getCategoriesLogout/")
            .then((res) => {
                setLoading(false);
                if (res.data.result) {
                    setCategories(res.data.categories)
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);

    var activityArray = [];
    const myArray = () => {
        activity.map((item) => (
            activityArray.find(arr => arr == item.category.id) ? true : activityArray.push(item.category.id)
        ))
    }

    const getCategoryId = (e) => {
        if (e == categoryId) {
            setCategoryId(null)
        } else {
            setCategoryId(e)
        }
    }
    useEffect(() => {
        setShow(true)
    }, []);

    const tr2en = (number, title) => {
        const urlTitle = title.replaceAll('Ğ', 'g')
            .replaceAll('Ü', 'u')
            .replaceAll('Ş', 's')
            .replaceAll('I', 'i')
            .replaceAll('İ', 'i')
            .replaceAll('Ö', 'o')
            .replaceAll('Ç', 'c')
            .replaceAll('ğ', 'g')
            .replaceAll('ü', 'u')
            .replaceAll('ş', 's')
            .replaceAll('ı', 'i')
            .replaceAll('ö', 'o')
            .replaceAll('ç', 'c')
            .replaceAll(' ', '_')
            .replaceAll("'", '_')
            .replaceAll('"', '_').toLowerCase();
        // window.location.href = (globals.url + "a/" + number + "/" + urlTitle)
        return urlTitle
    }

    // const removeDate = () => {
    //     setDate([{
    //         startDate: new Date(),
    //         endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
    //         key: "selection"
    //     }])
    //     setInitialDate([{
    //         startDate: new Date(),
    //         endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
    //         key: "selection"
    //     }])
    // }
    
    const removeDate = () => {
        const resetDate = {
            startDate: new Date(),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
            key: "selection",
        };
    
        setDate([resetDate]); // Tarihleri sıfırla
        setInitialDate([resetDate]); // Başlangıç tarihlerini sıfırla
        localStorage.removeItem("selectedDate"); // localStorage'dan tarih bilgisini kaldır
        window.location.reload(); // Sayfayı yeniden yükle
    };
    
    const removeCity = () => {
        setCity(0); // Şehir bilgisini sıfırla
        localStorage.removeItem("selectedCity"); // localStorage'dan şehir bilgisini kaldır
        window.location.reload(); // Sayfayı yeniden yükle
    };

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Home Page'
            },
            activity_number: activity.length,
            event: 'home_page'
        })

    }, [activity])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    useEffect(() => {
        setLoading(true)
        const fetchImages = async () => {
            try {
                const response = await HTTP.get('/getAdds');
                const sortedImages = response.data.sort((a, b) => a.order - b.order);
                setImagesSlide(sortedImages.map(image => ({ ...image, photo: null })));
            } catch (error) {
                console.error('Veri alma hatası:', error);
            }finally{
                setLoading(false)
            }
        };

        fetchImages();
    }, []);

    const filteredData = imagesSlide.filter(item => item.status);

    const settings = {
        dots: false,
        infinite: filteredData.length > 1, // Eğer birden fazla görsel yoksa sonsuz döngüyü kapat
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false, // Yönlendirme oklarını kaldır
    };
   
    
    
    // (date[0]?.startDate !== initialDate[0]?.startDate  || date[0]?.endDate!==initialDate[0].endDate || city>0)

    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("vidipass_slogan")}</title>
                <meta name="description" content={_("meta_content_05")}/>
                <meta name="robots" content="index, follow"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            {loading && <Loader/>}
            {/* <Modal show={show}  onHide={() => setShow(false)}>
                        <Modal.Header closeButton closeVariant="black" style={{color:"pink",background:"#f8f8f8"}}>
                            <Modal.Title>  <img src={images.vidipass_logo} className="w-50" alt="..."/></Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{background:"linear-gradient(90deg, #00A1E4, #2E81D2, #933CAC, #D31095, #EC008C)"}}>
                            <div style={{
                                display: "flex",
                                alignItems:"center",
                                justifyContent: "center",
                                height: "",
                                color:"white",
                                marginTop:20,
                                textAlign:"center"  
                            }}>
                                <Modal.Title >{_("Türkiye Cumhuriyeti’ nin 100. Yılı Kutlu Olsun! 💯🇹🇷")}</Modal.Title>
                            </div>
                        </Modal.Body>
                    </Modal> */}
            <Navbar />

            <div className="position-sticky d-flex justify-content-end" style={{ zIndex: 999, top:'70%' }}>
                <div className="position-absolute">
                    <div class="btn-group dropstart">
                        <button onClick={()=>gtmTag('follow_button')} className="rounded-start text-truncate" data-bs-toggle="dropdown" aria-expanded="false"
                            style={{ height: 120, borderWidth: 1,   borderColor:"#EC008C", backgroundColor: '#933CAC', width: 30, color:'#FFFFFF' }}>

                            <span className="box"><span class="bi bi-plus-circle-fill me-1"></span>{_('follow')}</span>
                        </button>
                        <div className="dropdown-menu border-2" style={{borderColor: "#933CAC",height:230}}>
                            <span className="justify-content-center d-flex homeText" style={{ fontSize: 15 }} >{_("social_media")}</span>

                            <div onClick={() => window.open('https://actividi.com/vidipass', '_blank') + setSocialModal(false) + gtmTag('actividi_link')} className="d-flex homeText justify-content-start align-items-center p-2 rounded mb-1">
                                <img src={images.actividi_logo} alt="actividi logo" width="40" height="auto" />
                                <span className="ms-3 d-inline-block text-truncate" style={{ fontSize: 14 }}>actividi.com/vidipass</span>
                            </div>

                            {/* <div onClick={() => window.open('https://instagram.com/vidipass', '_blank') + setSocialModal(false) + gtmTag('instagram_link')} className="d-flex homeText justify-content-start align-items-center p-2 rounded mb-1">
                                        <img src={images.instagram_logo} alt="instagram logo" width="40" height="auto" />
                                        <span className="ms-3 d-inline-block text-truncate" style={{ fontSize: 14 }}>instagram.com/vidipass</span>
                                    </div>   */}

                            <div className="d-flex align-items-start p-2 rounded">
                                <img src={images.instagram_logo} alt="Instagram logo" width="40" height="auto"/>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mx-3 homeText" onClick={() => window.open('https://instagram.com/vidipass', '_blank') + setSocialModal(false) + gtmTag('instagram_link')}>
                                        <span className="d-inline-block text-truncate" style={{ fontSize: 14 }}>instagram.com/vidipass</span>
                                    </div>
                                    <div className="d-flex align-items-center mx-3 homeText" onClick={() => window.open('https://instagram.com/vidipass_tr', '_blank') + setSocialModal(false) + gtmTag('instagram_link')}>
                                        <span className="d-inline-block text-truncate" style={{ fontSize: 14 }}>instagram.com/vidipass_tr</span>
                                    </div>
                                </div>
                            </div>

                            <div onClick={() => window.open('https://whatsapp.com/channel/0029VaLN2f77oQhhnW8uBH40', '_blank') + setSocialModal(false) + gtmTag('whatsapp_link')} className="d-flex homeText justify-content-start align-items-center p-2 rounded">
                                <img src={images.whatsapp_logo} alt="whatsapp logo" width="40" height="auto" />
                                <span className="ms-3 d-inline-block text-truncate col-8" style={{ fontSize: 14 }}>whatsapp.com/vidipass</span>
                            </div>

                            <div onClick={() => window.open('https://www.youtube.com/@vidipass', '_blank') + setSocialModal(false) + gtmTag('youtube_link')} className="d-flex homeText justify-content-start align-items-center p-2 rounded">
                                <img src={images.youtube_logo} alt="youtube logo" width="40" height="auto" />
                                <span className="ms-3 d-inline-block text-truncate col-8" style={{ fontSize: 14 }}>youtube.com/vidipass</span>
                            </div>

                            {/* <div onClick={() => window.open('https://www.tiktok.com/@vidipass', '_blank') + setSocialModal(false) + gtmTag('tiktok_link')} className="d-flex homeText justify-content-start align-items-center p-2 rounded">
                                        <img src={images.tiktok_logo} alt="tiktok logo" width="40" height="auto" />
                                        <span className="ms-3 d-inline-block text-truncate col-8" style={{ fontSize: 14 }}>tiktok.com/vidipass</span>
                                    </div> */}

                            <div className="d-flex align-items-start p-2 rounded">
                                <img src={images.tiktok_logo} alt="tiktok logo" width="40" height="auto"/>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mx-3 homeText" onClick={() => window.open('https://www.tiktok.com/@vidipass', '_blank') + setSocialModal(false) + gtmTag('tiktok_link')} >
                                        <span className="d-inline-block text-truncate" style={{ fontSize: 14 }}>tiktok.com/vidipass</span>
                                    </div>
                                    <div className="d-flex align-items-center mx-3 homeText" onClick={() => window.open('https://www.tiktok.com/@vidipass_tr', '_blank') + setSocialModal(false) + gtmTag('tiktok_link')}>
                                        <span className="d-inline-block text-truncate" style={{ fontSize: 14 }}>tiktok.com/vidipass_tr</span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex align-items-start p-2 rounded">
                                <img src={images.x_logo} alt="x logo" width="39" height="auto"/>
                                <div className="d-flex flex-column ms-1">
                                    <div className="d-flex align-items-center mx-3 homeText" onClick={() => window.open('https://x.com/vidipass', '_blank') + setSocialModal(false) + gtmTag('x_link')}>
                                        <span className="d-inline-block text-truncate" style={{ fontSize: 14 }}>x.com/vidipass</span>
                                    </div>
                                    <div className="d-flex align-items-center mx-3 homeText" onClick={() => window.open('https://x.com/VidipassTR', '_blank') + setSocialModal(false) + gtmTag('x_link')}>
                                        <span className="d-inline-block text-truncate" style={{ fontSize: 14 }}>x.com/vidipassTR</span>
                                    </div>
                                </div>
                            </div>

                            <div className="justify-content-center d-flex">
                                <hr className="homeText col-11 ">
                                </hr>
                            </div>

                            <span className="justify-content-center d-flex homeText" style={{ fontSize: 15 }} >{_("mobile_application")}</span>

                            <div onClick={() => window.open('https://apps.apple.com/tr/app/vidipass/id6476486667', '_blank') + setSocialModal(false) + gtmTag('apple_link')} className="d-flex homeText justify-content-start align-items-center p-2 rounded ">
                                <img src={images.apple_icon_logo} alt="apple logo" width="40" height="auto" />
                                <span className="ms-3 d-inline-block text-truncate col-8" style={{ fontSize: 14 }}>apps.apple.com/vidipass</span>
                            </div>

                            <div onClick={() => window.open('https://play.google.com/store/apps/details?id=com.vidipass', '_blank') + setSocialModal(false) + gtmTag('google_link')} className="d-flex homeText justify-content-start align-items-center p-2 ms-2 rounded">
                                <img src={images.google_icon_logo} alt="google logo" width="35" height="auto" />
                                <span className="ms-3 d-inline-block text-truncate col-8" style={{ fontSize: 14 }}>play.google.com/vidipass</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div onClick={()=>gtmTag('slide_image')} className="container-flud mt-5">
                <div className="slider-container">
                    <Slider {...settings}>
                        {filteredData.map((item) => (
                            <div key={item.id} onClick={item.number ? ()=>window.location.href = globals.url + 'a/' + item.number : ()=>window.location.href = globals.url}>
                                <img className="slider-image" src={item.photo_url} alt={`Slide ${item.order}`} />
                            </div>
                        ))}
                    </Slider>
                </div>

            </div>

            <div className="container">
                <div className="rounded-3 mt-lg-4 mb-2">
                    <div className="justify-content-center">
                    <form
                                    className="row"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const start = moment(date[0].startDate)
                                            .format("YYYY-MM-DD");
                                        const end = moment(date[0].endDate)
                                            .format("YYYY-MM-DD");
                                        setLoading(true);
                                        HTTP.get(
                                            `activities/getAllLogout?term=${search.trim()}&start=${start}&end=${end}&city=${city}&cats`)
                                            .then((res) => {
                                                setLoading(false);
                                                if (res.data.result) {
                                                    setActivity(res.data.activities.data);
                                                } else {
                                                    setLoading(false);
                                                }
                                            })
                                            .catch(setLoading(true));
                                    }} >

                            <div 
                            className="d-flex align-items-center justify-content-center"
                                style={{ gap: "12px" }}
                            >
                                {/* Search Input */}
                                <div className="flex-fill" style={{ maxWidth: "600px" }}>
                                    <input
                                        type="search"
                                        className="form-control bg-white text-muted"
                                        placeholder={_("search")}
                                        aria-label={_("search")}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        style={{ fontSize: "14px", padding: "8px" }}
                                    />
                                </div>

                                {/* Date Button */}
                                <button
                                    onClick={() => setOpenDate(!openDate)}
                                    type="button"
                                    className="btn d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: "#933CAC",
                                        color: "#FFFFFF",
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <span ><img style={{width:21, marginBottom: 3}} src={images.calendar}/></span>
                                </button>
                                {/* City Button */}
                                <div className="d-flex justify-content-center align-items-center position-relative">
                                    <button
                                        className="btn p-0"
                                        style={{
                                            backgroundColor: "#933CAC",
                                            width: "40px",
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => document.getElementById("citySelect").focus()}
                                    >
                                        <i style={{ fontSize: "20px" }}><img style={{width:20, marginBottom: 3}} src={images.city}/></i>
                                    </button>
                                    <select
                                        id="citySelect"
                                        className="form-select position-absolute"
                                        style={{
                                            left: 0,
                                            top: 0,
                                            width: "40px",
                                            height: "40px",
                                            opacity: 0,
                                            cursor: "pointer",
                                            borderRadius: 7,
                                        }}
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city || ""}
                                    >
                                        <option value="" key="default"></option>
                                        {cities.map((cityOption, i) => (
                                            <option value={cityOption.key} key={i + 1}>
                                                {cityOption.value[0] + cityOption.value.slice(1).toLowerCase()}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Search Button */}
                                <button
                                    onClick={() => gtmTag("search")}
                                    type="submit"
                                    className="btn d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: "#933CAC",
                                        color: "#FFFFFF",
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "7px",
                                    }}
                                >
                                    <img
                                       style={{width:20, marginBottom: 2}}
                                        src={images.search}
                                    />
                                </button>
                            </div>



                            {openDate && (
                                <>
                                    <div style={{ backgroundColor: "#EFF2F7", borderRadius: 4 }}>
                                        <div className="d-flex justify-content-center">
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={(item) => setDate([item.selection])}
                                                moveRangeOnFirstSelection={false}
                                                ranges={date}
                                                className="date"
                                                dateDisplayFormat="dd.MM.yyyy"
                                                minDate={new Date(2022, 12, 1)}
                                                locale={
                                                    lang == "af"
                                                        ? af
                                                        : lang == "ar"
                                                            ? ar
                                                            : lang == "az"
                                                                ? az
                                                                : lang == "bg"
                                                                    ? bg
                                                                    : lang == "bs"
                                                                        ? bs
                                                                        : lang == "cy"
                                                                            ? cy
                                                                            : lang == "de"
                                                                                ? de
                                                                                : lang == "el"
                                                                                    ? el
                                                                                    : lang == "es"
                                                                                        ? es
                                                                                        : lang == "fi"
                                                                                            ? fi
                                                                                            : lang == "fr"
                                                                                                ? fr
                                                                                                : lang == "he"
                                                                                                    ? he
                                                                                                    : lang == "hi"
                                                                                                        ? hi
                                                                                                        : lang == "hr"
                                                                                                            ? hr
                                                                                                            : lang == "hu"
                                                                                                                ? hu
                                                                                                                : lang == "it"
                                                                                                                    ? it
                                                                                                                    : lang == "ja"
                                                                                                                        ? ja
                                                                                                                        : lang == "kk"
                                                                                                                            ? kk
                                                                                                                            : lang == "ko"
                                                                                                                                ? ko
                                                                                                                                : lang == "lb"
                                                                                                                                    ? lb
                                                                                                                                    : lang == "mk"
                                                                                                                                        ? mk
                                                                                                                                        : lang == "ms"
                                                                                                                                            ? ms
                                                                                                                                            : lang == "nl"
                                                                                                                                                ? nl
                                                                                                                                                : lang == "nn"
                                                                                                                                                    ? nn
                                                                                                                                                    : lang == "pt"
                                                                                                                                                        ? pt
                                                                                                                                                        : lang == "ro"
                                                                                                                                                            ? ro
                                                                                                                                                            : lang == "ru"
                                                                                                                                                                ? ru
                                                                                                                                                                : lang == "sq"
                                                                                                                                                                    ? sq
                                                                                                                                                                    : lang == "sr"
                                                                                                                                                                        ? sr
                                                                                                                                                                        : lang == "sv"
                                                                                                                                                                            ? sv
                                                                                                                                                                            : lang == "tk"
                                                                                                                                                                                ? tr
                                                                                                                                                                                : lang == "tr"
                                                                                                                                                                                    ? tr
                                                                                                                                                                                    : lang == "tw"
                                                                                                                                                                                        ? zhTW
                                                                                                                                                                                        : lang == "uk"
                                                                                                                                                                                            ? uk
                                                                                                                                                                                            : enUS
                                                }
                                                rangeColors={["#D500F9"]}
                                                color="#D500F9"
                                            />
                                        </div>
                                        <div className="col text-center" onClick={e => setOpenDate(false)}>
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                {_('ok')}
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Selected Filters */}
                            {(date[0]?.startDate.getTime() !== initialDate[0]?.startDate.getTime() || 
                            date[0]?.endDate.getTime() !== initialDate[0]?.endDate.getTime() || 
                            city > 0) && (
                                <div className="d-flex flex-columns justify-content-center mt-2 gap-2">
                                    <div>
                                        {/* Tarih filtresi */}
                                        {(date[0]?.startDate.getTime() !== initialDate[0]?.startDate.getTime() || 
                                        date[0]?.endDate.getTime() !== initialDate[0]?.endDate.getTime()) && (
                                            <div className="col d-flex justify-content-center"> 
                                                <div className="rounded-2 d-flex justify-content-center card-chipsy">
                                                    <p className="mb-0 text-muted p-2">
                                                        {moment(date[0].startDate).format("DD.MM.YY ")}-
                                                        {moment(date[0].endDate).format(" DD.MM.YY")}
                                                    </p>
                                                </div>
                                                <span 
                                                    onClick={() => {
                                                        removeDate(); 
                                                    }}
                                                    className="d-flex align-items-center bi bi-x-circle-fill p-1"
                                                    style={{ color: '#933CAC' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {/* Şehir filtresi */}
                                        {city > 0 && (
                                            <div className="col d-flex justify-content-center">
                                                <div className="rounded-2 d-flex justify-content-center card-chipsy">
                                                    <p className="mb-0 text-muted p-2">
                                                        {cities.find((c) => c.key === city)?.value}
                                                    </p>
                                                </div>
                                                <span 
                                                    onClick={() => {
                                                        removeCity();
                                                    }}
                                                    className="d-flex align-items-center bi bi-x-circle-fill p-1"
                                                    style={{ color: '#933CAC' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>

                {activity.length > 0 ?
                    <>
                        <div className="row mb-lg-4 mt-lg-4">
                            <span style={{fontSize:25}} className="headTittle">{_("featured_categories")}</span>
                        </div>
                        <div className="category-group">
                            <div className="row">
                                {myArray()}
                                {categories.map((item) => (
                                    activityArray.find(arr => arr == item.id) &&
                                    <div className="col-2 category-element">
                                        <a href="#"  onClick={()=>gtmTag('selected_category')}>
                                            <img width="auto" height="auto" onClick={e => getCategoryId(item.id)}
                                                src={
                                                    item.name == "culture" ? images.culture :
                                                        item.name == "art" ? images.art :
                                                            item.name == "eat_drink" ? images.eat_drink :
                                                                item.name == "fun" ? images.fun :
                                                                    item.name == "scientific" ? images.scientific :
                                                                        item.name == "sport" ? images.sport :
                                                                            item.name == "anniversary" ? images.anniversary :
                                                                                item.name == "business" ? images.business :
                                                                                    item.name == "interior" ? images.interior :
                                                                                        item.name == "outdoor" ? images.outdoor :
                                                                                            item.name == "opening" ? images.opening :
                                                                                                item.name == "exhibition" ? images.exhibition :
                                                                                                    item.name == "show" ? images.show :
                                                                                                        item.name == "celebration" ? images.celebration :
                                                                                                            item.name == "mention" ? images.mention :
                                                                                                                item.name == "holiday" ? images.holiday :
                                                                                                                    item.name == "ceremony" ? images.ceremony :
                                                                                                                        item.name == "education" ? images.education :
                                                                                                                            item.name == "conference" ? images.conference :
                                                                                                                                item.name == "fuar" ? images.fuar :
                                                                                                                                    item.name == "social" ? images.social :
                                                                                                                                        item.name == "religion" ? images.religion :
                                                                                                                                            item.name == "race" ? images.race : images.other
                                                }
                                                className={categoryId == item.id ? "category-element-img-select" : "category-element-img"}
                                                alt="categories"
                                            />
                                        </a>
                                        <p className="category-element-p text-truncate">
                                            {_(item.name)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row mb-3 mt-lg-1">
                            <h1 style={{fontSize:25}} className="headTittle">{_("upcoming_activities")}</h1>
                        </div>

                        <div className="row mt-1">
                            {activity.map((item, key) => (
                                (item.category.id == categoryId || categoryId == null) &&
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                                    <div className="card-back rounded p-1">
                                        <div className="card rounded border-0">
                                            <a href={globals.url + "a/" + item.number + "/" + tr2en(item.number, item.title)} onClick={() => gtmTag('activity_image')}>
                                                <img width='100%' height='auto' src={globals.imgUrl + item.id + "/thumb/" + item.photo_name + ".png?" + new Date(item.created_at).getTime()} className="card-img-top rounded" alt="activity photo" />
                                            </a>
                                            <div className="card-body">
                                                <h2 style={{fontSize:16}} className="homeText text-truncate">{item.title}</h2>
                                                <span className="homeText">{_(item.category.name)}</span>
                                                <div className="d-flex">
                                                    <div className="homeText">
                                                        <i style={{ color: "#933CAC" }} className="bi bi-calendar-check me-2"></i>
                                                        {moment(item.start_time).format("DD.MM.YYYY")}
                                                    </div>

                                                    {moment(item.end_time).diff(moment(item.start_time), 'hours') > 24 ? (
                                                        <>
                                                            <span style={{color: "#933CAC"}}>—</span>
                                                            <div className="homeText">
                                                                {moment(item.end_time).format("DD.MM.YYYY")}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="col homeText text-end">
                                                            <i style={{ color: "#933CAC" }} className="bi bi-clock me-2"></i>
                                                            {moment(item.start_time).format("HH:mm")}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="homeText mb-2">
                                                    <i style={{ color: "#933CAC" }} className="bi bi-geo-alt homeText me-2"></i>
                                                    {item.city.name}
                                                </div>

                                                {item.time > 0 ? (
                                                    <div className="text-center" onClick={() => gtmTag('ticket_button')}>
                                                        {/* <a className="text-decoration-none btn btn-primary cardButton rounded-2 " href={globals.url + "a/" + item.number}>
                                                                    {_("ticket")}
                                                              </a> */}
                                                        {item.activity_status == 0 ? (
                                                            <a className="text-decoration-none btn btn-primary cardButton rounded-2" href={globals.url + "a/" + item.number + "/" + tr2en(item.number, item.title)}>
                                                                {_("ticket")}
                                                            </a>
                                                        ) : (
                                                            <a className="text-decoration-none btn btn-primary cardButton rounded-2" href={globals.url + "a/" + item.number + "/" + tr2en(item.number, item.title)}>
                                                                {_("off_sold_out")}
                                                            </a>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="text-center" onClick={() => gtmTag('ticket_button')}>
                                                        <a className="text-decoration-none btn btn-primary cardButton rounded-2" href={globals.url + "a/" + item.number + "/" + tr2en(item.number, item.title)}>
                                                            {_("details")}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                    :
                    <div className="row mt-5">
                        <h4 className="homeText text-center">{_("there_is_on_activity_in_the_search_criteria")}</h4>
                    </div>
                }
            </div>
            <Footer />

        </div>
    );
};

export default Home;