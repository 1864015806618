import ".././index.css";
import { ThreeCircles, Oval, Puff, Triangle, Vortex } from 'react-loader-spinner';
import images from "../images/images"
import { useEffect, useState } from "react";
import { _ } from "../config/languages/i18n";


const Loader = () => {
    const [textIndex, setTextIndex] = useState(0);

  const messages = [_("loading"), _("please_wait"), _("almost_there")];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [messages.length]);

    return (
        // <div className="overlay">
        //     <div className="d-flex justify-content-center">
        //         <div className="position-absolute w-25 top-50 d-flex justify-content-center p-3">
        //             <ThreeCircles
        //                 height="75"
        //                 width="75"
        //                 color="#4fa94d"
        //                 wrapperStyle={{}}
        //                 wrapperClass={{}}
        //                 visible={true}
        //                 ariaLabel="three-circles-rotating"
        //                 outerCircleColor="#933CAC"
        //                 innerCircleColor="#EC008C"
        //                 middleCircleColor="#00A1E4"
        //             />
        //         </div>
        //     </div>
        // </div>
 
        <div className="custom-loader">
       
        {/* <div className="loader-image-container">
          <img style={{width:"47px"}} src={images.vidipass_trans} className="loader-image" />
        </div>
   */}
        <div className="loader-spinner">
          <Vortex
            height={120}
            width={120}
            colors={["#933CAC", "#EC008C", "#00A1E4", "#933CAC", "#EC008C", "#00A1E4"]}
            secondaryColor="#ccc"
            ariaLabel="oval-loading"
            visible={true}
          />
        </div>
        <div className="loader-text">
      {messages[textIndex]}
    </div>
        
      </div>
      
    
      
     
      
    )
}
export default Loader