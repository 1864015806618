import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useState, useEffect, useRef } from "react";
import globals from '../config/globals/globals';
import { _ } from "../config/languages/i18n";
import { registerAction } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import axios from "axios";

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [country_code, setCountry] = useState("TR");
    const [phone_number, setPhoneNumber] = useState("");
    const [type, setType] = useState("");
    const dispatch = useDispatch();
    const [verify, setVerify] = useState(false);
    const formData = new FormData();
    const [term, setTerm] = useState(false)
    const divRef = useRef(null)
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])
    useEffect(()=>{
		setUtc(new Date().getTimezoneOffset()/-60)
	},[])
	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    const onChange = (value) => {
        setVerify(true)
    }

    const onChangeTerm = (value) => {
        setTerm(!term);
    }

    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const lang = cookies.get("language")
    
    useEffect(() => {
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const [passwordShown, setPasswordShown] = useState("password");
    const toggle = () => {
        if (passwordShown === "password") {
            setPasswordShown("text");
            return;
        }
        setPasswordShown("password");
    };

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Register Page'
            },
            event: 'register_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("record")}</title>
                <meta name="description" content={_("meta_content_12")}/>
                <meta name="robots" content="index, follow"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mt-5">
                <div className="card col-md-6 offset-md-3">
                    <div className="row justify-content-center my-auto">
                        <div className="col-md-8 col-10">
                            <div className="row justify-content-center mb-3">
                                <img width={25} height={'auto'} className="w-25 mb-2" src={images.register_button_icon} alt="register" />
                                <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("record")}</h1>
                            </div>
                            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="faq_tab_1-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_1" type="button" role="tab" aria-controls="faq_tab_1" aria-selected="true">
                                        <div className="d-flex flex-column lh-lg">
                                            <i className="bx bxs-plane-alt"></i>
                                            <span className="homeText">{_("phone")}</span>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="faq_tab_2-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_2" type="button" role="tab" aria-controls="faq_tab_2" aria-selected="false">
                                        <div className="d-flex flex-column lh-lg">
                                            <i className="bx bxs-shopping-bag"></i>
                                            <span className="homeText">{_("e_mail")}</span>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content mt-2" id="myTabContent">
                                <div className="tab-pane fade active show" id="faq_tab_1" role="tabpanel" aria-labelledby="faq_tab_1-tab">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (phone && password.length > 7 && verify) {
                                            setPassword("");
                                            setPhone("");
                                            setType("");
                                            setPhoneNumber("");
                                            setCountry("");
                                            formData.append("phone", phone);
                                            formData.append("phone_number", phone);
                                            formData.append("country_code", country_code);
                                            formData.append("password", password);
                                            formData.append("type", 2);
                                            formData.append('lang', lang)
                                            formData.append("ip_address", ip);
                                            formData.append("device_name", device);
                                            formData.append("utc", utc);
                                            dispatch(registerAction(formData));
                                        }

                                        if (!phone) {
                                            toast(_('phone_must_be_enter'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: 'error'
                                            })
                                        }

                                        if (password.length < 8) {
                                            toast(_('password_must_be_at_least_eight_characters'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: 'error'
                                            })
                                        }
                                        // if (!term){
                                        //     toast(_('term_must_be_enter'), {
                                        //         position: "top-right",
                                        //         autoClose: 3000,
                                        //         hideProgressBar: false,
                                        //         closeOnClick: false,
                                        //         pauseOnHover: false,
                                        //         draggable: false,
                                        //         progress: undefined,
                                        //         theme: "light",
                                        //         closeButton:false
                                        //         })
                                        // }
                                        if (!verify) {
                                            toast(_('verify_must_be_enter'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: 'error'
                                            })
                                        }

                                    }}>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("phone")}</label>
                                            <PhoneInput
                                                placeholder={_("phone")}
                                                value={phone}
                                                onChange={setPhone}
                                                className="headerInput"
                                                onCountryChange={setCountry}
                                                defaultCountry="TR"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("create_a_password")}</label>
                                            <div className="input-group">
                                                <input type={passwordShown} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={_("password")} className="form-control" />
                                                <i className=" btn btn-secondary text-center btn2-color" onClick={toggle}>
                                                    {passwordShown === "password" ? (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-slash-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    )}
                                                </i>
                                            </div>
                                        </div>
                                        <div className="form-check mt-2">
                                            <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                {/* <input  type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} /> */}
                                                {_("terms_of_use_01")} <a href={globals.url + "i/legal"} target="_blank" style={{ color: "#933CAC", textDecoration: "none" }}>{_("terms_of_use_02")}</a>{_("terms_of_use_03")}
                                            </label>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6LfwQKwmAAAAABGb8zXTCPFFzB3MxWqDCPVd_-Lr"
                                            onChange={onChange}
                                            className="d-flex justify-content-center"
                                        />
                                        <div onClick={() => gtmTag('register_button')} className="row justify-content-center my-3 px-3">
                                            <button className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("register")}</button>
                                        </div>
                                    </form>
                                </div>

                                <div className="tab-pane fade" id="faq_tab_2" role="tabpanel" aria-labelledby="faq_tab_2-tab">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (email && password.length > 7 && verify) {
                                            setPassword("");
                                            setEmail("");
                                            setType("");
                                            formData.append("email", email);
                                            formData.append("password", password);
                                            formData.append("type", 1);
                                            formData.append('lang', lang)
                                            formData.append("ip_address", ip);
                                            formData.append("device_name", device);
                                            formData.append("utc", utc);
                                            dispatch(registerAction(formData));
                                        }

                                        if (!email) {
                                            toast(_('email_must_be_enter'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: 'error'
                                            })
                                        }

                                        if (password.length < 8) {
                                            toast(_('password_must_be_at_least_eight_characters'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: 'error'
                                            })
                                        }
                                        // if (!term){
                                        //     toast(_('term_must_be_enter'), {
                                        //         position: "top-right",
                                        //         autoClose: 3000,
                                        //         hideProgressBar: false,
                                        //         closeOnClick: false,
                                        //         pauseOnHover: false,
                                        //         draggable: false,
                                        //         progress: undefined,
                                        //         theme: "light",
                                        //         closeButton:false
                                        //         })
                                        // }
                                        if (!verify) {
                                            toast(_('verify_must_be_enter'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: 'error'
                                            })
                                        }
                                    }}>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("e_mail")}</label>
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={_("e_mail")} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("create_a_password")}</label>
                                            <div className="input-group">
                                                <input type={passwordShown} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={_("password")} className="form-control" />
                                                <i className=" btn btn-secondary text-center btn2-color" onClick={toggle}>
                                                    {passwordShown === "password" ? (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-slash-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    )}
                                                </i>
                                            </div>
                                        </div>
                                        <div className="form-check mt-2">
                                            <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                {/* <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} /> */}
                                                {_("terms_of_use_01")} <a href={globals.url + "i/legal"} target="_blank" style={{ color: "#933CAC", textDecoration: "none" }}>{_("terms_of_use_02")}</a>{_("terms_of_use_03")}
                                            </label>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6LfwQKwmAAAAABGb8zXTCPFFzB3MxWqDCPVd_-Lr"
                                            onChange={onChange}
                                            className="d-flex justify-content-center"
                                        />
                                        <div onClick={() => gtmTag('register_button')} className="row justify-content-center my-3 px-3">
                                            <button className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("register")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="bottom text-center">
                            <p href="#" className="sm-text">{_("do_you_have_an_account")}
                                <a href={globals.url + "i/login"}><button className="btn-white ms-3">{_("login")}</button></a>
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="card card2 order-1 me-1 mb-1 ">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}

            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}
export default Register